.body--continually-landing-page {
    background-color: #fff;
    &[data-cx-sidebar="visible"] {
        .continually-landing-page {
            opacity: 0.2;
        }
    }
    a.powered-by {
        display: block;
        font-size: 1.4em;
        background-color: $blue-dark;
        color: #fff;
        text-align: center;
        text-decoration: none;
        transition: background-color 0.2s ease-in-out;
        padding-bottom: 1.4em;
        div {
            padding: 1.4em 1.4em 0;
        }
        .faux {
            border-bottom: 1px solid $brand-primary;
        }
        &:hover {
            background-color: lighten($blue-dark, 10%);
        }
        @include mq(min, $mq-sm) {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            display: flex;
            justify-content: space-between;
        }
    }
}

.body--continually-landing-page-full {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    min-height: auto;
    height: 100%;
    overflow: hidden;
    .continually-landing-page--full {
        opacity: 1 !important;
        .cover {
            &--wave {
                &:before {
                    background-size: 200% 100%;
                    background-position: center 400px;
                }
            }
        }
    }
    #continually-container {
        #continually-sidebar {
            position: fixed;
            right: 0 !important;
            left: 0 !important;
            transform: none;
            background-color: transparent !important;
            width: 100% !important;
            box-shadow: none;
            top: 9em !important;
            height: auto !important;
            max-height: none;
            bottom: 70px;
            transition: 0.2s;
            @include mq(min, $mq-sm) {
                bottom: 56px;
            }
            @include mq(min, $mq-md) {
                top: 9.5em !important;
            }
        }
    }
}

.body--continually-landing-page-custom-branding {
    #continually-container {
        #continually-sidebar {
            bottom: 0;
        }
    }
}
.body--continually-landing-page-empty-header {
    .cover__header {
        display: none !important;
    }
    #continually-container {
        #continually-sidebar {
            top: 0.5em !important;
        }
    }
}

.body--continually-landing-page-no-header {
    @include mq(max, $mq-md) {
        .cover__header {
            visibility: hidden;
            opacity: 0;
            height: 0 !important;
            overflow: hidden;
        }
        #continually-container {
            #continually-sidebar {
                top: 0.5em !important;
            }
        }
    }
}
// These styles are used on the landing pages and the landing page config page (on the preview)

.continually-landing-page {
    transition: opacity 0.2s ease-in-out;
    min-height: 90vh;
    @include mq(min, $mq-sm) {
        display: flex;
        min-height: 100vh;
        overflow-y: auto;
    }
    &--full {
        display: flex;
        min-height: auto;
        height: auto;
        overflow: auto;
        flex: 1 auto;
        .cover {
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: relative;
            display: flex;
            flex-direction: column;
            &--wave {
                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-image: url(../images/landing-pages/wave.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center 200px;
                }
            }
            &--img {
                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.3);
                }
            }
            &__header {
                padding: 1.5em 3.5em;
                position: relative;
                z-index: 1;
                display: flex;
                align-items: center;
                margin-bottom: 0;
                height: 9em;
                transition: height 0.2s;
                @include mq(min, $mq-md) {
                    padding-top: 2.4em;
                    padding-bottom: 2.4em;
                    height: 9.5em;
                }
                h1 {
                    font-weight: 500;
                    margin-bottom: 0.2em;
                    font-size: 2em;
                    @include mq(min, $mq-md) {
                        font-size: 2.2em;
                    }
                }
                h2 {
                    font-size: 14px;
                    margin-bottom: 0;
                    @include mq(min, $mq-md) {
                        font-size: 16px;
                    }
                }
                .logo {
                    max-width: none;
                    position: static;
                    margin-right: 1em !important;
                    margin-left: 0 !important;
                    img {
                        max-width: 6em;
                        max-height: 6em;
                    }
                }
            }
            &__bot {
                display: flex;
                flex-direction: column;
                max-width: 230px;
                margin: 10px auto 0 auto;
                flex: 1;
                margin-bottom: 70px;
                position: relative;
                img {
                    &:first-child {
                        flex: 1;
                        object-fit: contain;
                        object-position: top;
                    }
                }
            }
        }
    }
    .logo {
        max-width: 50px;
        @include mq(min, $mq-sm) {
            max-width: 10em;
            position: absolute;
            left: 3.2em;
            top: 3.2em;
        }
    }
    figure.cover {
        flex: 1;
        background-color: $blue;
        background-position: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        text-align: center;
        padding: 1.6em;
        min-height: 31em;
        @include mq(min, $mq-sm) {
            width: 50%;
            min-height: 100vh;
        }
    }
    section {
        @include mq(min, $mq-sm) {
            order: 2;
            width: 50%;
            display: flex;
            align-items: center;
            flex: 1;
        }
        .content {
            padding: 6.4em 3.2em 12.8em;
            text-align: center;
            @include mq(min, $mq-sm) {
                text-align: left;
                padding: 6.4em 6.4em 12.8em;
            }
        }
        figure.secondary {
            margin-bottom: 3.2em;
            img {
                max-height: 8em;
                @include mq(min, $mq-sm) {
                    max-height: 10em;
                }
            }
        }
        h1 {
            font-size: 3.6em;
            margin-bottom: 0.5em;
            font-weight: 700;
            .font-roboto & {
                @include bold-roboto;
            }
        }
        h2 {
            font-size: 2.4em;
            margin-bottom: 1.5em;
            font-weight: 300;
            .font-roboto & {
                @include light-roboto;
            }
        }
        p.lead {
            color: #6a5d5d;
            font-size: 2.4em;
            margin-bottom: 0.5em;
            .font-roboto & {
                @include regular-roboto;
            }
        }
        .custom-content {
            font-size: 1.8em;
            color: #949494;
            .font-roboto & {
                @include regular-roboto;
                h2 {
                    font-weight: 300;
                }
                h1,
                h3,
                h4,
                h5,
                h6,
                strong {
                    font-weight: 700;
                }
                a.btn {
                    font-weight: 500;
                }
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: $black;
                margin: 0.5em 0 0 0;
            }
            h1 {
                font-size: 1.8em;
                font-weight: 700;
            }
            h2 {
                font-size: 1.8em;
                font-weight: 300;
            }
            h3 {
                font-size: 1.4em;
                font-weight: 700;
            }
            h4 {
                font-size: 1.4em;
                color: $black-light;
                font-weight: 700;
            }
            h5 {
                font-size: 1em;
                @include uppercase;
                font-weight: 700;
            }
            h6 {
                font-size: 1em;
                font-weight: 700;
            }
            p {
                font-size: 1em;
                color: #949494;
                line-height: 1.375;
                margin: 1.618em 0 0 0;
            }
        }
        ul,
        ol {
            margin: 1.618em 0 0 0;
            li {
                font-size: 1em;
                margin-top: 0.3em;
            }
        }
        a.btn,
        .content__btn {
            margin-top: 2em;
            padding: 0.75em 2em 0.6em;
            border: none;
            white-space: normal;
            width: 100%;
            @include mq(min, $mq-sm) {
                width: auto;
            }
            i {
                font-size: 1.5em;
                position: relative;
                top: -0.1em;
                left: -0.5em;
            }
            i,
            span.label {
                @include inline-block;
            }
            span.label {
                font-size: 1.3em;
            }
        }
    }
}
