.form {
  &--variable-mapping {
    fieldset {
      margin-bottom: 2em;
      label {
        margin-bottom: 0.3em;
      }
    }
  }
  &__text {
    font-size: 1.3em;
    @include medium-italic;
    &--muted {
      color: #9b9b9b;
    }
  }
  &__panel {
    background: #e3e3e3;
    padding: 2em 2em 0 2em;
    .fas {
      font-size: 1.4em;
      margin-right: 0.5em;
      margin-top: 0.2em;
    }
  }
  &--embed {
    input[type="url"] {
      padding-top: 0.7em;
      padding-bottom: 0.6em;
    }
  }

  .fieldset-domain {
    p {
      font-size: 1.5em;
    }
  }
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="time"],
input[type="date"],
textarea {
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 1em 1em 0.8em;
  background-color: #fff;
  color: $black;
  line-height: 1.15;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }

  @include placeholder {
    color: $black-light;
  }
}

input[type="file"] {
  font-size: 1.6em;
  color: $black;
}

p.faux-input {
  .horizontal-form & {
    padding: 0.8em 1em;
    margin: 0;
  }
}

input[type="submit"]:focus,
select:focus {
  outline: none;
}

input[type="checkbox"]:focus,
label.checkbox-label a:focus {
  outline: thin dotted;
}

.input-group {
  $self: &;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  &--multiple {
    align-items: center;
    #{$self}__text {
      font-size: 1.6em;
      margin-left: 1em;
      margin-right: 1em;
    }
  }

  &__gradient {
    display: none;
    align-items: center;
    &--visible {
      display: flex;
    }
  }

  &-addon {
    flex: 0 0 3em;
    padding: 0 0.7em;
    font-size: 1.6em;
    @include medium;
    background-color: $gray-200;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    border-right: none;
    border-radius: 3px 0 0 3px;
    #{$self}--multiple & {
      padding-top: 0.65em;
      padding-bottom: 0.65em;
    }
  }

  &__value {
    background: white;
    padding: 1em;
    margin-bottom: 0;
    border: 1px solid #ddd;
    font-size: 1.5em;
    padding-right: 3em;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    min-width: 24.1em;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="url"],
  input[type="tel"],
  input[type="search"],
  input[type="number"],
  input[type="time"],
  input[type="date"],
  textarea {
    flex: 1;
    border-radius: 0 3px 3px 0;
  }

  .validationMessage {
    width: 100%;
  }
}

form {
  fieldset {
    margin: 0 0 2em;

    &.form-actions,
    &.date {
      margin: 0;
    }

    &.date {
      .datepicker {
        border: none;
        border-radius: 0;
        width: 100%;
        &--nav {
          border-bottom: 1px solid #efefef;
          border-top: 1px solid #efefef;
        }
      }
    }

    &.btn-wrap {
      display: block;
      position: relative;
    }

    label {
      display: block;
      margin: 0 0 1em 0;
      font-size: 1.6em;
      @include medium;
      &.text-regular {
        @include regular;
      }

      &.check {
        @include regular;
        font-size: 1.4em;

        a {
          color: inherit;
        }
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="url"],
    input[type="tel"],
    input[type="search"],
    input[type="number"],
    input[type="time"],
    input[type="date"],
    textarea {
      display: block;
      width: 100%;

      @include mq(min, $mq-md) {
        max-width: 350px;
      }

      font-size: 1.6em;
      appearance: none;

      &:focus,
      &.active {
        outline: none;
        border-color: #666;
      }
    }

    p.faux-input {
      display: block;
      width: 100%;
      font-size: 1.6em;
    }

    textarea {
      height: 150px;
      &.textarea-noresize {
        resize: none;
      }
    }

    select {
      min-width: 100%;

      @include mq(min, 860px) {
        min-width: 25%;
        width: auto;
      }

      margin: 0;
      font-size: 1.6em;
    }

    &.radio,
    &.check {
      ul {
        margin-top: 5px;
        list-style: none;

        li {
          margin: 0 0 5px 0;

          p.form-help {
            margin: 0 0 0 20px;
          }

          &:last-child {
            margin: 0;
          }
        }

        label {
          display: inline;
          @include regular;
          font-size: 1em;
        }
      }

      ul ul {
        margin-left: 30px;
        margin-bottom: 1em;
      }
    }

    &.check--2col {
      @include mq(min, $mq-sm) {
        ul {
          column-count: 2;
        }

        li {
          position: relative;
          padding-left: 22px;

          input[type="radio"],
          input[type="checkbox"] {
            position: absolute;
            z-index: 1;
            left: 0;
            top: 1px;
          }
        }
      }
    }

    &.link {
      a {
        font-size: 1.4em;
        text-decoration: none;
        color: $black-light;

        &:hover {
          color: $brand-primary;
        }
      }
    }

    .form-help {
      display: inline-block;
      margin: 0.5em 0;
      font-size: 1.4em;
      color: #999;
      &:empty {
        display: none;
      }

      &.has-error {
        color: $error;
      }

      a {
        color: #999;
      }

      code {
        font-size: 0.8em;
        display: inline-block;
        margin: 0 0.33em;
        padding: 0.33em 0.45em;
        border-radius: 2px;
      }

      img,
      span {
        @include inline-block;
        margin-right: 0.3em;
      }
    }

    div.form-help {
      display: block;

      p {
        font-size: 1em;
      }
    }

    label + .form-help {
      margin-top: 0;
    }

    &.has-error {
      input[type="text"],
      input[type="email"],
      input[type="password"],
      input[type="url"],
      input[type="tel"],
      input[type="search"],
      input[type="number"],
      input[type="time"],
      input[type="date"],
      textarea {
        border: 1px solid $error;
      }

      .form-help {
        color: $error;
      }
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    margin: 0;
    margin-right: 4px;
    line-height: normal;
  }

  input[type="file"] {
    display: block;
    margin-bottom: 0.5em;
    font-size: 1.6em;
  }

  input[type="range"] {
    display: block;
    width: 100%;
  }

  select[multiple],
  select[size] {
    height: auto;
  }

  input[type="file"]:focus,
  input[type="radio"]:focus,
  input[type="checkbox"]:focus {
    outline: thin dotted;
  }
}

form.form-inline {
  fieldset {
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="url"],
    input[type="tel"],
    input[type="search"],
    input[type="number"],
    input[type="time"],
    input[type="date"] {
      display: inline-block;
      max-width: none;
    }
  }
}

form.horizontal-form {
  fieldset:not(.form-actions) {
    border-top: 1px solid $grey;
    padding: 2px 0;
    margin: 0;
    @include transition;

    @include mq(min, $mq-sm) {
      @include clearfix;
    }

    label {
      @include mq(min, $mq-sm) {
        float: left;
        width: 5em;
        margin-right: 16px;
        padding-top: 0.8em;
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="url"],
    input[type="tel"],
    input[type="search"],
    input[type="number"],
    input[type="time"],
    input[type="date"],
    textarea {
      @include mq(min, $mq-sm) {
        float: left;
        width: calc(100% - 5em - 16px);
      }
    }

    .pulldown-inner {
      @include mq(min, $mq-sm) {
        position: relative;
        top: -1px;
      }
    }

    p.faux-input {
      @include mq(min, $mq-sm) {
        float: left;
        width: calc(100% - 5em - 16px);
        border: 1px solid #fff;
      }
    }
  }
}

form.horizontal-form[data-state="editing"] {
  fieldset:not(.form-actions) {
    padding: 10px 0;
  }
}

$horizontal-form-height: 2.4em;

form.horizontal-form--1-field {
  @include mq(min, $mq-md) {
    display: flex;

    .fieldset--input {
      flex: 2;
    }

    .fieldset--button {
      flex: 1;
    }
  }

  fieldset {
    margin: 0;
    display: block;
  }

  input[type="text"],
  input[type="email"] {
    box-sizing: border-box;
    height: $horizontal-form-height;
    width: 100%;
    max-width: none;
    padding: 0 1em;
    background-color: #fff;
    font-size: 1.8em;
    border-radius: 0.2em 0 0 0.2em;
    border: 1px solid #ddd;
    margin: 0;

    @include mq(max, $mq-md) {
      border-radius: 3px;
    }

    @include mq(min, $mq-md) {
      border-right: none;
    }
  }

  .btn {
    border-radius: 0 0.2em 0.2em 0;
    margin: 0;
    height: $horizontal-form-height;
    line-height: $horizontal-form-height;
    padding: 0 2em;
    font-size: 1.8em;
    width: 100%;
    cursor: pointer;
    border: none;

    @include mq(max, $mq-md) {
      border-radius: 3px;
      margin-top: 0.5em;
    }
  }

  .btn-wrap {
    display: block;
  }

  .btn-wrap .btn--spinner {
    left: 47%;
  }
}

form.edit-form {
  fieldset.form-actions {
    @include mq(min, $mq-sm) {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="time"],
input[type="date"],
textarea {
  &.error {
    border: 1px solid $error;
  }
}

label.error {
  color: $error;
  @include medium;
  margin: 5px 0 0 0;
}

label.disabled {
  color: #bfbfbf;
}

input[type="time"]::-webkit-clear-button {
  display: none;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button,
input.input-number-no-arrows::-webkit-inner-spin-button,
input.input-number-no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-number {
  &::-webkit-inner-spin-button {
    -webkit-appearance: inner-spin-button !important;
    opacity: 1;
    pointer-events: auto;
  }
}

.form-error-summary,
.validationMessage {
  background-color: $error;
  color: #fff;
  padding: 1em;
  margin-bottom: $spacer;
  text-align: left;
  &--icon {
    color: $red;
    background: none;
    font-size: 1.4em;
    padding: 0;
    margin-top: 1em;
    display: inline-block;
    margin-bottom: 0.5em;
    &:before {
      content: "\f06a";
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      margin-right: 0.3em;
    }
  }

  span {
    font-size: 1.5em;
  }
}

/* Change Autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

.label {
  &--sm {
    font-size: 1.2em;
    margin-bottom: 0.4em;
    color: #989898;
  }
}

$track-color: rgba(#d3d3d3, 0.58) !default;
$thumb-color: white !default;

$thumb-radius: 50% !default;
$thumb-height: 20px !default;
$thumb-width: 20px !default;
$thumb-shadow-size: 2px !default;
$thumb-shadow-blur: 2px !default;
$thumb-shadow-color: rgba(0, 0, 0, 0.2) !default;
$thumb-border-width: 1px !default;
$thumb-border-color: $brand-green !default;

$track-width: 24em !default;
$track-height: 5px !default;

$track-radius: 5px !default;
$contrast: 5% !default;

@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color,
    0 0 $shadow-size lighten($shadow-color, 5%);
}

@mixin track {
  cursor: default;
  height: $track-height;
  transition: all 0.2s ease;
  width: $track-width;
}

@mixin thumb {
  @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
  background: $thumb-color;
  border: $thumb-border-width solid $thumb-border-color;
  border-radius: $thumb-radius;
  box-sizing: border-box;
  cursor: default;
  height: $thumb-height;
  width: $thumb-width;
}

.form-control-range[type="range"] {
  -webkit-appearance: none;
  background: transparent;
  width: $track-width;

  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    outline: 0;

    &::-webkit-slider-runnable-track {
      background: $track-color;
    }

    &::-ms-fill-lower {
      background: $track-color;
    }

    &::-ms-fill-upper {
      background: $track-color;
    }
  }

  &::-webkit-slider-runnable-track {
    @include track;
    background: $track-color;
    border-radius: $track-radius;
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
    margin-top: ($track-height * 0.5 - $thumb-height * 0.5);
  }

  &::-moz-range-track {
    @include track;
    background: $track-color;
    border-radius: $track-radius;
    height: $track-height * 0.5;
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &::-ms-track {
    @include track;
    background: transparent;
    border-color: transparent;
    border-width: ($thumb-height * 0.5) 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    border-radius: ($track-radius * 2);
  }

  &::-ms-fill-upper {
    background: $track-color;
    border-radius: ($track-radius * 2);
  }

  &::-ms-thumb {
    @include thumb;
    margin-top: $track-height * 0.25;
  }

  &:disabled {
    &::-webkit-slider-thumb,
    &::-moz-range-thumb,
    &::-ms-thumb,
    &::-webkit-slider-runnable-track,
    &::-ms-fill-lower,
    &::-ms-fill-upper {
      cursor: not-allowed;
    }
  }
}

.continually-radio {
  display: inline-flex;
  align-items: flex-start;
  line-height: 1;
  input[type="radio"] {
    display: none;
    + span {
      background: #dadada;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5em;
      border: 1px solid transparent;
      transition: 0.2s;
      &:hover {
        border-color: $blue-new;
      }
    }
    &:checked + span {
      background: $blue-new;
      &:hover {
        background: $blue-dark-new;
      }
      &::before {
        content: "";
        background: white;
        width: 6px;
        height: 6px;
        display: inline-block;
        border-radius: 50%;
      }
    }
    &:active + span {
      background: $blue-new;
    }
    &:active:checked + span {
      background: $blue-dark-new;
      &::before {
        content: none;
      }
    }
  }
}

.continually-checkbox {
  display: flex;
  align-items: center;
  input[type="checkbox"] {
    display: none;
    + span {
      background: #dadada;
      width: 14px;
      height: 14px;
      border-radius: 3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      transition: 0.2s;
      &:hover {
        border-color: $blue-new;
        background: $blue-light;
      }
    }
    &:checked + span {
      background: $blue-new;
      &:hover {
        background: $blue-dark-new;
      }
      &::before {
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
        color: white;
        display: inline-block;
        font-size: 12px;
        line-height: 1.1;
      }
    }
    &:active + span {
      background: $blue-new;
    }
    &:active:checked + span {
      background: $blue-dark-new;
      &::before {
        content: none;
      }
    }
  }
}

.continually-select {
  border: 1px solid #dadada;
  min-width: 30em;
  border-radius: 5px;
  display: inline-flex;
  position: relative;
  &--multiple {
    max-width: 80em;
  }
  &__inner {
    padding: 0.9em 1.5em 0em 1.5em;
    min-height: 5em;
  }
  &__select,
  &__list {
    display: none;
  }
  &__list {
    border: 1px solid #dadada;
    list-style: none;
    padding-top: 1em;
    max-height: 20em;
    overflow: hidden;
    overflow-y: auto;
    background: white;
    position: absolute;
    top: 102%;
    left: 0;
    width: 100%;
    border-radius: 5px;
    &.dropdown-active {
      display: block;
    }
  }
  &__item {
    font-size: 1.6em;
    padding: 0.8em 1em;
    display: flex;
    align-items: center;
    &--selected {
      background: $blue-light;
      i.fa-check {
        display: inline-block;
      }
    }
    i {
      display: none;
    }
    &:hover {
      background: $blue-new;
      color: white;
    }
  }
  &__chips {
    &:not(:empty) {
      + .continually-select__text {
        display: none;
      }
    }
  }
  &__text {
    line-height: 1;
    color: rgba($black, 0.5);
  }
}

.continually-chip {
  font-size: 1.6em;
  background: $blue-light;
  color: #222;
  padding: 0.45em 1em;
  line-height: 1;
  border-radius: 5em;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
}

.custom-checkbox-switch {
  position: relative;
  appearance: none;
  width: 2.5em;
  height: 1.25em;
  border-radius: 30px;
  cursor: pointer;
  background: #dadada;
  transition: box-shadow 0.3s ease-in-out, padding 0.25s ease-in-out;
  transition-delay: 0.1s, 0s;
  border: 1px solid transparent;
  margin-right: 0.5em !important;
  &:hover {
    background: #e2e7f6;
    border: 1px solid #0f47d7;
  }
  &:focus {
    outline: 0 !important;
  }
  &:checked {
    transition-delay: 0s, 0s;
    box-shadow: inset 0 0 0 2.62857em $blue-new;
    padding-left: 1.25em;
    &:hover {
      background: #0836ac;
    }
  }
  &::before,
  &::after {
    content: "";
    display: block;
  }
  &::before {
    display: inline-block;
    height: 0.875em;
    width: 0.875em;
    margin: 2px;
    background-color: #fff;
    border-radius: 2.1em;
    transition: all 0.1s ease 0.1s;
  }
  &:active::before {
    transition: all 0.1s ease 0.05s;
  }
}
