.btn {
  display: inline-block;
  line-height: 1.25;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.6em 1.6em 0.5em;
  font-size: 1.6em;
  border-radius: 4px;
  @include transition(background-color);
  cursor: pointer;
  @include medium;
  &:hover,
  &.hover {
    text-decoration: inherit;
  }

  &:focus,
  &.focus {
    outline: 0;
  }

  &.disabled,
  &-disabled,
  &--disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
    pointer-events: none;
  }

  &--start-new-conversation {
    font-weight: 400;
    i {
      margin-right: 0.5em;
    }
  }
  &--flex {
    display: inline-flex;
    align-items: center;
  }
  &--connect {
    border: 1px solid $blue;
    background: white;
    color: $blue;
    font-weight: 500;
    &:hover {
      @extend .btn--blue;
    }
  }
  &--white {
    background: white;
  }
  &--bot-builder {
    color: $black;
    border: none;
    background: rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    min-width: 4em;
    min-height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: white;
      background: $blue;
    }
  }
  &--black {
    background: $black;
    border-radius: 2px;
    font-size: 1.6em;
    color: white;
    &:hover {
      color: white;
      background: darken($black, 10%);
    }
  }
  &--dark-black {
    background: #222;
    border-radius: 2px;
    font-size: 1.6em;
    color: white;
    &:hover {
      color: white;
      background: darken(#222, 10%);
    }
  }
  &--md {
    padding: 0.9em 2em;
    font-size: 1.6em;
    border-radius: 4px;
  }
  &--radius-4 {
    border-radius: 4px;
  }
  &--search {
    background: #fff;
    border: 1px solid #cdcdcd;
    height: auto;
    border-radius: 6px;
    padding: 0.8em 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    color: #000;
    &:hover {
      border-color: $black;
    }
    i.fa-search {
      color: $black;
    }
  }
  &--dark-new {
    padding: 0.9em 2em;
    font-size: 1.6em;
    border-radius: 4px;
    background: #333;
    color: white;
    &:hover {
      background: darken(#333, 5%);
      color: white;
    }
  }
  &--dark-new-normal {
    padding: 0.5em 1.5em;
  }
}

.btn-pill,
.btn--pill {
  border-radius: 3em;
  padding-top: 0.5em;
  padding-bottom: 0.3em;
}

.btn-icon {
  padding: 0.1em 0.5em;
  font-size: 1em;
}

.btn--icon-no-padding {
  padding: 0;
  font-size: 1em;
}

.btn-sm,
.btn--sm {
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1.3em;
  @include regular;
}

.btn--font-15 {
  font-size: 15px;
}

.btn-lg,
.btn--lg {
  padding: 0.75em 2.2em 0.6em;
  font-size: 2em;
}

.btn-block,
.btn--block {
  display: block;
  width: 100%;
}

.btn-short,
.btn--short {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.btn-primary,
.btn--primary {
  color: white;
  background-color: $brand-primary;
  border-color: darken($brand-primary, 10%);
  svg {
    fill: white;
  }
  &:hover,
  &.hover {
    color: white;
    background-color: darken($brand-primary, 10%);
  }
  &.btn--processing {
    color: rgba(white, 0.1);
  }
}

.btn--primary-border {
  border-color: $brand-primary;
  &:hover {
    border-color: darken($brand-primary, 10%);
  }
}

.btn-secondary,
.btn--secondary {
  color: $brand-primary;
  background-color: white;
  border-color: $brand-primary;
  svg {
    fill: $brand-primary;
  }
  &:hover,
  &.hover {
    color: darken($brand-primary, 10%);
    background-color: white;
    border-color: darken($brand-primary, 10%);
    svg {
      fill: darken($brand-primary, 10%);
    }
  }
  &.btn--processing {
    color: rgba($brand-primary, 0.2);
  }
}

.btn--ghost {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
  svg {
    fill: #fff;
  }
  &:hover,
  &.btn--processing {
    color: rgba(#fff, 0.2);
  }
}

.btn--secondary--grey {
  color: $black-lighter;
  background-color: white;
  border-color: $black-lighter;
  svg {
    fill: $black-lighter;
  }
  &:hover,
  &.hover {
    color: $black-light;
    background-color: white;
    border-color: $black-light;
    svg {
      fill: $black-light;
    }
  }
  &.btn--processing {
    color: rgba($black-lighter, 0.2);
  }
}

.btn--grey-no-border {
  background: #e5e5e5;
  border-color: #e5e5e5;
  color: $black;
  &:hover {
    color: $black;
    background: darken(#e5e5e5, 10%);
    border-color: darken(#e5e5e5, 10%);
  }
}

.btn--blue {
  color: white;
  background-color: $brand-blue;
  border-color: darken($brand-blue, 10%);
  svg {
    fill: white;
  }
  &:hover,
  &.hover {
    color: white;
    background-color: darken($brand-blue, 10%);
  }
  &.btn--processing {
    color: rgba(white, 0.1);
  }
}

.btn--blue-new {
  color: white;
  background-color: $blue-new;
  border-color: darken($blue-new, 10%);
  svg {
    fill: white;
  }
  &:hover,
  &.hover {
    color: white;
    background-color: darken($blue-new, 10%);
  }
  &.btn--processing {
    color: rgba(white, 0.1);
  }
}

.btn--blue-border {
  border-color: $brand-blue;
  &:hover {
    border-color: darken($brand-blue, 10%);
  }
}

.btn-orange,
.btn--orange {
  color: white;
  background-color: #fa6400;
  border-color: #fa6400;
  svg {
    fill: white;
  }
  &:hover,
  &.hover {
    color: white;
    background-color: darken(#fa6400, 10%);
    border-color: darken(#fa6400, 10%);
  }
}

.btn-grey,
.btn--grey {
  @include medium;
  color: $black;
  background-image: linear-gradient(#fbfbfb, #f6f6f6);
  border-color: #d1d1d1;
  box-shadow: 0 1px 2px 0 rgba(210, 189, 189, 0.5);
  svg {
    fill: $black;
  }
  &:hover,
  &.hover {
    color: darken($black, 10%);
    background-color: white;
    border-color: darken(#d1d1d1, 10%);
    svg {
      fill: darken($black, 10%);
    }
  }
}

.btn-warning,
.btn--warning {
  color: white;
  background-color: $red;
  border-color: $red;
  svg {
    fill: white;
  }
  &:hover,
  &.hover {
    color: white;
    background-color: darken($red, 10%);
    border-color: darken($red, 10%);
  }
}

.btn-quiet,
.btn--quiet {
  color: $black-light;
  background-color: white;
  border-color: $black-light;
  svg {
    fill: $black-light;
  }
  &:hover,
  &.hover {
    color: darken($black-light, 20%);
    background-color: white;
    border-color: darken($black-light, 20%);
    svg {
      fill: darken($black-light, 20%);
    }
  }
  &.btn--processing {
    color: rgba($black-light, 0.2);
  }
}

.btn-delete,
.btn--delete {
  color: $red;
  background-color: #fff;
  border-color: $red;
  svg {
    fill: $red;
  }
  &:hover,
  &.hover {
    color: darken($red, 10%);
    background-color: #fff;
    border-color: darken($red, 10%);
    svg {
      fill: darken($red, 10%);
    }
  }
}

.btn-more,
.btn--more {
  svg {
    fill: $black-light;
    @include wh(16px, 16px);
  }
  i {
    color: $black-light;
  }
  &:hover {
    svg {
      fill: darken($black-light, 10%);
    }
  }
}

.btn--slack {
  border: 1px solid #c7cacd;
  background-color: #fff;
  border-radius: 6px;
  color: $black;
  padding: 0.7em 1em 0.6em;
  @include transition(border-color);
  .icon {
    max-width: 2.2em;
  }
  .label {
    font-size: 1.6em;
  }
  &:hover {
    color: $black;
    border-color: $black-light;
  }
}

.btn-link,
.btn--link {
  color: $brand-primary;
  background-color: transparent;
  border-color: transparent;
  @include regular;
  svg {
    fill: $brand-primary;
  }
  &:hover,
  &.hover {
    color: darken($brand-primary, 10%);
    background-color: transparent;
    svg {
      fill: darken($brand-primary, 10%);
    }
  }
}

.btn-dark-gray {
  color: #adadad;
  background-color: #373738;
  border-color: #373738;
  svg {
    fill: white;
  }
  &:hover,
  &.hover {
    color: white;
  }
}

.btn-dark-black {
  color: #fff;
  background-color: #2d2d2d;
  border-color: #2d2d2d;
  svg {
    fill: white;
  }
  &:hover,
  &.hover {
    background-color: darken(#2d2d2d, 10%);
    border-color: darken(#2d2d2d, 10%);
  }
}

.btn-page-action {
  text-decoration: none;
  cursor: pointer;
  span {
    @include inline-block;
  }
  .icon {
    @include circle;
    background-color: $brand-primary;
    color: #fff;
    margin-right: 10px;
    @include transition;
    position: relative;
    svg {
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      fill: #fff;
      transform: translate(-50%, -50%);
    }
  }
  .label {
    font-size: 1.8em;
    @include regular;
    color: $black;
  }
  &:hover {
    text-decoration: none;
    .icon {
      background-color: $black;
    }
    // .label { color: $brand-primary; }
  }
}

p,
li {
  .btn {
    font-size: initial;
  }
  .btn-sm,
  .btn--sm {
    font-size: 0.8em;
  }
  .btn-lg,
  .btn--lg {
    font-size: 1.1em;
  }
}

td,
th,
.flex-table-cell {
  .btn {
    font-size: initial;
  }
  .btn-sm,
  .btn--sm {
    font-size: 0.7em;
  }
  .btn-lg,
  .btn--lg {
    font-size: 1.1em;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  .btn {
    font-size: initial;
    margin-left: 10px;
  }
  .btn-sm,
  .btn--sm {
    font-size: 0.33em;
  }
}

// Special items inside a button
.btn {
  .avatar,
  .label,
  .icon,
  svg {
    @include inline-block;
  }
  .avatar,
  .icon,
  svg,
  i {
    &:not(:last-child) {
      margin-right: 0.5em;
    }
    &:not(:first-child) {
      margin-left: 0.5em;
    }
  }
}

button:focus {
  outline: none;
}

.btn--dropdown-options {
  display: inline-block;
  position: relative;
  .btn {
    svg.arrow-down2 {
      @include wh(0.6em, 0.6em);
      fill: currentColor;
      margin-left: 0.6em;
    }
  }
}

.btn--dropdown-options__dropdown {
  opacity: 0;
  pointer-events: none;
  &.dropdown-active {
    animation: showDropdown 0.1s ease;
    opacity: 1;
    pointer-events: auto;
  }
  position: absolute;
  z-index: $zindex-dropdown;
  left: 0;
  @include mq(max, $mq-sm) {
    right: -2em;
  }
  top: calc(100% - 1px);
  background-color: white;
  border: 1px solid $black-light;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
  border-radius: 4px;
  max-width: 30em;
  overflow: hidden;
  .dropdown-options-scroll {
    max-height: 40em;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1.4em 1.6em;
  }
  h4 {
    font-size: 1.4em;
    line-height: 1;
    margin: 0 0 1em;
    @include medium;
    @include uppercase;
  }
  .option {
    display: block;
    margin-bottom: 0.5em;
    white-space: nowrap;
    font-size: 1.3em;
    input[type="checkbox"] {
      margin-right: 0.1em;
    }
  }
  .option--mandatory {
    color: $black-lighter;
  }
  .button-option {
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    padding: 1em;
    padding-bottom: 0.8em;
    font-size: 1.6em;
    white-space: nowrap;
    &:last-of-type {
      border-bottom: none;
    }
    &:hover {
      background-color: #f6f6f6;
    }
    i {
      margin-left: -0.2em;
      margin-right: 0.2em;
    }
  }
  .save-prefs {
    border-top: 1px solid $black-lighter;
    padding: 1em 1.6em;
  }
}

.btn--spinner {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  @include spinner(1.8em, 1px, #fff);
  margin-left: -0.9em;
  margin-top: -0.9em;
  pointer-events: none;
}

.btn-wrap {
  position: relative;
  display: inline-block;
  &--block {
    display: block;
  }
  .btn {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .btn--primary + .btn--spinner {
    border-color: #fff;
    border-left-color: transparent;
  }
  .btn--secondary + .btn--spinner {
    border-color: $brand-primary;
    border-left-color: transparent;
  }
  .btn--quiet + .btn--spinner {
    border-color: $black-light;
    border-left-color: transparent;
  }
}

.btn--processing {
  pointer-events: none;
  & + .btn--spinner {
    opacity: 1;
    visibility: visible;
  }
}

.btn--sm + .btn--spinner {
  @include spinner(1.6em, 1px, #fff);
  margin-left: -0.8em;
  margin-top: -0.8em;
}

.btn--lg + .btn--spinner {
  @include spinner(2.4em, 1px, #fff);
  margin-left: -1.2em;
  margin-top: -1.2em;
}

.btn + .btn-explainer {
  @include inline-block;
  font-size: 1.3em;
  margin-left: 1em;
}

.btn--transparent {
  background: transparent;
  border: none;
  outline: none;
}

.btn--text-medium {
  font-weight: 500;
}
