.color-hover-red {
  &:hover {
    color: $red;
  }
}

.color-hover-black {
  &:hover {
    color: $black;
  }
}

.color-red {
  color: $red !important;
  &--light {
    color: #ff6464 !important;
  }
  @at-root {
    a#{&}:hover {
      color: darken($red, 10%) !important;
    }
  }
}

.color-gray {
  color: $gray-600;
  &--500 {
    color: $gray-500;
  }
}

.color-b3b3b3 {
  color: #b3b3b3;
}

.color-696969 {
  color: #696969;
}

.color-9b9b9b {
  color: #9b9b9b;
}

.color-blue {
  color: $blue !important;
}

.color-black {
  color: $black !important;
}

.color-primary {
  color: $brand-primary !important;
}

.color-white {
  color: white;
}

.color-white-force {
  color: white !important;
}

.color-orange {
  color: #f85e1f;
}

.color-yellow {
  color: #f7b500;
}

.color-hover-white {
  &:hover {
    color: white;
  }
}

.color-orange-light {
  color: #f0ad4e;
}

.color-inherit {
  color: inherit;
}
