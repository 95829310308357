@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes focuswarmdown {
  0% {
    background-color: #fcf8e3;
  }
  100% {
    background-color: inherit;
  }
}

@keyframes warmdown--conversation {
  0% {
    opacity: 0;
    box-shadow: 0 0 2em 0 rgba(#f1e492, 0.75);
  }
  100% {
    opacity: 1;
    background-color: inherit;
    box-shadow: 0 0 2em 0 rgba(#f1e492, 0);
  }
}

@keyframes loadingbar {
  0% {
    right: 100%;
  }
  100% {
    right: 0;
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}

@keyframes showDropdown {
  from {
    opacity: 0;
    transform: translateY(-12px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes showFlyout {
  from {
    opacity: 0;
    transform: translate(-50%, -12px);
  }

  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes showFlyoutDown {
  from {
    opacity: 0;
    transform: translate(0, -12px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes showTooltip {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showPopover {
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInDown {
  0% {
    transform: translate3d(0, -50% 0);
    visibility: visible;
  }

  100% {
    transform: translateZ(0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  &--fast {
    animation-duration: .5s;
  }
}

.animated.infinite {
  animation-iteration-count: infinite;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@keyframes cbwarmdown {
  0% {
    border: 1px solid $black;
    box-shadow: 0 0 0 1px $black;
  }
  100% {
    border: 1px solid #e5dede;
    box-shadow: 0 1px 5px 0 rgba(181, 181, 181, 0.5);
  }
}

@keyframes showEmbeddedMsg {
  from {
    opacity: 0;
    transform: translateX(100px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes showEmbeddedMsgReverse {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes showSlider {
  from {
    // opacity: 0;
    left: -40em;
  }

  to {
    // opacity: 1;
    left: 0;
  }
}
