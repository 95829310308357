html {
  height: 100%;
}

html,
body {
  background-color: $body-bg;
}

.wip {
  border: 2px dashed $black;
  padding: 1em;
  font-size: 1.4em;
  background-color: $grey;
  margin-bottom: 2em;
  font-family: $font-family-monospace;
  a {
    color: $blue;
  }
}

[role="link"],
[role="button"] {
  cursor: pointer;
}

[aria-hidden="true"] {
  display: none !important;
}
// [aria-hidden="false"] { display: block; }

::-moz-selection {
  background-color: #000;
  color: #fff;
  text-shadow: none;
  fill: white;
}

::selection {
  background-color: #000;
  color: #fff;
  text-shadow: none;
  fill: white;
}

a {
  color: $brand-primary;
  text-decoration: underline;
  &:hover {
    color: darken($brand-primary, 15%);
    text-decoration: underline;
  }
  &.link-inline-minor {
    display: inline-block;
    margin-left: 8px;
    font-size: 0.8em;
  }
  &:focus {
    outline: none;
  }
}

a.inherit {
  color: inherit;
}

.flag {
  background-color: $brand-primary;
  color: #fff;
  font-size: 1em;
  @include uppercase;
  @include medium;
  padding: 0.5em 0.5em 0.3em 0.5em;
  border-radius: 2px;
  line-height: 1;
  &.flag--hot {
    background-color: #d0021b;
  }
  &.flag--cold {
    background-color: #89c0ce;
  }
  &.flag--new {
    background-color: #ded23b;
  }
  &.flag--good {
    background-color: #5add3c;
  }
  &.flag--success {
    background-color: #d1f1c4;
    color: $black;
  }
  &.flag--info {
    background-color: #d9edf7;
    color: $black;
  }
  &.flag--warning {
    background-color: #fcf8e3;
    color: $black;
  }
  &.flag--danger,
  &.flag--error {
    background-color: $error;
  }
  &.flag--live-chat {
    background-color: #7ed321;
  }
  &.flag--red {
    background-color: $brand-red;
  }
  &.flag--blue {
    background-color: $brand-blue;
  }
  &.flag--green {
    background-color: $brand-green;
  }
  &.flag--open {
    background-color: #f5a623;
  }
  &.flag--closed {
    background-color: transparent;
    border: 1px solid $red;
    color: $red;
  }
  &.flag--grey {
    background: #e0e0e0;
    color: $black;
  }
  &.flag--variable {
    background: #dcdcdc;
    color: $black;
  }
  &--conv {
    font-size: 1.2em;
    padding-top: 0.3em;
    padding-bottom: 0.1em;
    border: 1px solid transparent;
  }
  &--opacity {
    opacity: 0.3;
  }
  &--tag {
    font-size: 12px;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    text-transform: none;
    padding-left: 0.7em;
    padding-right: 0.7em;
    letter-spacing: 0;
    display: inline-block;
    cursor: pointer;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
  }
  &--black {
    background: #353535;
  }
  &--purple {
    background: #5155ca;
  }
}

.bullet {
  background-color: $brand-primary;
  @include circle(1em);
  &.bullet--new {
    background-color: #ded23b;
  }
  &.bullet--updated {
    background-color: #4a90e2;
  }
}

.hint {
  position: relative;
  background-color: $clay;
  color: #fff;
  border-radius: 3px;
  padding: 1em;
  .message {
    font-size: 1.4em;
    a {
      color: #fff;
    }
  }
  &.hint--arrow-down:after {
    top: 100%;
    left: 3em;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba($clay, 0);
    border-top-color: $clay;
    border-width: 8px;
    margin-left: -8px;
  }
  svg {
    display: inline-block;
    margin-right: 3px;
    @include wh(11px, 11px);
    fill: #fff;
  }
}

.module {
  position: relative;
  margin-bottom: $spacer * 2;
  .module-action {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
  }
}

.panel {
  header {
    position: relative;
  }
  .panel-action {
    position: absolute;
    z-index: 1;
    right: 1em;
    @include vertical-align;
    .btn {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0.5em;
      svg {
        @include wh(0.8em, 0.8em);
      }
    }
  }
}

.info-panel {
  border: 1px solid #ddd;
  border-radius: 2px;
  padding: $spacer;
  margin-bottom: $spacer-y * 2;
  p {
    font-size: 1.4em;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &--muted {
    background: #f0f0f0;
    border: none;
    position: relative;
    .opportunity-body & {
      background: #e2e2e2;
      border-radius: 6px;
    }
  }
  &--large {
    padding: 2.5em 2.5em 3em 2.5em;
  }
  &--invalid {
    p {
      font-size: 1.5em;
    }
  }
  &--dark {
    background: #d3d2d3 !important;
  }
  &--medium {
    padding-left: 3em;
    padding-top: 2em;
    padding-bottom: 2em;
    max-width: 62em;
    p {
      font-size: 1.6em;
    }
  }
  &--white {
    background: #fafafa;
    border: 1px solid #cacaca;
    border-radius: 3px;
    padding: 4em;
  }
  &--billing {
    background: #f7fafd;
    border: none;
    max-width: 42em !important;
    padding: 3em;
    padding-right: 4em;
    @include mq(min, $mq-xl) {
      padding-right: 8em;
    }
    h4 {
      font-size: 2.2em;
    }
    .info-panel__title {
      font-size: 1.6em;
    }
    .info-panel__title,
    p {
      color: $black;
    }
  }
  &--padding-sm {
    padding: 1em 1.5em;
    .alert__close {
      position: static;
      margin: 0 0 0 1em;
      padding: 0;
      transform: none;
      svg {
        width: 1.2em;
        height: 1.2em;
        fill: #a4a4a4;
      }
    }
  }
  &__title,
  p#{&}__text,
  &__link {
    color: #626262;
    margin-bottom: 0.3em;
    font-size: 1.5em;
  }
  &__title {
    @include medium;
  }
  &__close {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    font-size: 1.5em;
    color: #a5a5a5;
    &:hover {
      color: black;
    }
  }
  &--blue {
    background: #ebf9f7;
    border-radius: 4px;
    border: none;
    padding: 3em 4em;
    .info-panel__title {
      font-weight: normal;
      font-size: 2em;
      margin-bottom: 1em;
    }
  }
  &__list {
    margin-top: 1em;
    list-style-type: none;
  }
  &__item {
    margin-bottom: 1em;
  }
}

.hidden:not(.visible) {
  @include offscreen;
}

.sr-only {
  @include offscreen;
}

.loading,
.js-loading {
  position: relative;
  &:before {
    position: absolute;
    z-index: 2;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(#fff, 0.9);
  }
  &:after {
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 50%;
    @include spinner;
  }
}

.loading-conversation-builder,
.loading-interstitial,
.loading-placeholder,
.loading-conversation-preview {
  text-align: center;
  padding: 8em 4em;
  &--timeline {
    padding-top: 3em;
    padding-bottom: 3em;
  }
  .spinner {
    @include spinner(2em, 1px);
    margin: 0 auto 1em;
  }
  figure {
    max-width: 6.4em;
    margin: 0 auto 1em;
    opacity: 0.5;
  }
  p {
    margin-bottom: 0;
    @include italic;
    font-size: 1.3em;
  }
}

section:focus {
  outline: none;
}

.ui-mockup {
  border: 1px solid #ddd;
  border-top: 3em solid #ddd;
  position: relative;
  border-radius: 3px 3px 0 0;
  background-color: #fff;
  display: block;
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  &:before {
    display: block;
    position: absolute;
    z-index: 2;
    content: "";
    top: -1.8em;
    left: 1em;
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    background-color: #ff6059;
    box-shadow: 0 0 0 2px #ff6059, 1.5em 0 0 2px #ffc02f, 3em 0 0 2px #28ca42;
  }
  &.ui-browser:after {
    display: block;
    position: absolute;
    content: "";
    top: -2.5em;
    left: 5.5em;
    width: calc(100% - 6em);
    height: 2em;
    border-radius: 2px;
    background-color: white;
  }
}

.ui-browser--url-input {
  border: 1px solid #ddd;
  border-radius: 5px 5px 0 0;
  .browser-chrome {
    background-color: #ddd;
    height: 5em;
    position: relative;
  }
}

.error-summary {
  margin-bottom: $spacer;
  padding: 1.6em;
  background-color: $error;
  color: #fff;
  * {
    color: #fff;
  }
  ul {
    margin-left: 2em;
  }
}

.nowrap {
  white-space: nowrap;
}

@keyframes dots-1 {
  from {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
}
@keyframes dots-2 {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes dots-3 {
  from {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}
@-webkit-keyframes dots-1 {
  from {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
}
@-webkit-keyframes dots-2 {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes dots-3 {
  from {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}

.dots span {
  animation: dots-1 0.75s infinite steps(1);
}

.dots span:first-child + span {
  animation-name: dots-2;
}

.dots span:first-child + span + span {
  animation-name: dots-3;
}

.ql-toolbar.ql-snow {
  background-color: #fff;
  border-radius: 3px 3px 0 0;
  .ql-formats {
    margin-right: 7px;
    padding-right: 4px;
    border-right: 1px solid rgba(#000, 0.15);
    &:last-of-type {
      border-right: none;
      margin-right: 0;
    }
  }
  button {
    border-radius: 2px;
    margin-right: 2px;
    &:hover,
    &.ql-active {
      background-color: rgba(#000, 0.05);
      .ql-stroke {
        stroke: #444;
      }
      .ql-fill {
        fill: #444;
      }
    }
  }
  .flyout-wrapper {
    display: inline-block;
  }
  button.ql-giphy {
    display: flex;
    align-items: center;
    width: auto;
    span {
      text-transform: uppercase;
      @include bold;
      font-size: 1em;
      padding: 0.3em;
      border-radius: 3px;
      line-height: 1;
      background-color: $black;
      color: #fff;
    }
  }
}
.ql-container.ql-snow {
  background-color: #fff;
  border-radius: 0 0 3px 3px;
  @include regular;
  .font-roboto & {
    @include regular-roboto;
    b,
    strong,
    th,
    .variable {
      @include bold-roboto;
    }
  }
  p {
    font-size: 1.4em;
  }
  s,
  .variable {
    display: inline-block;
    padding: 0.4em 0.8em 0.2em;
    line-height: 1;
    background-color: #ed97fc;
    border: 1px solid #c05ec0;
    border-radius: 30px;
    white-space: nowrap;
    text-decoration: none;
  }
  .emoji.ap {
    display: inline;
    @include quill-emoji-reset;
  }
  img:not(.emoji) {
    display: block;
    width: 100%;
    margin-bottom: 1em;
  }
}

.inline-flex {
  display: inline-flex;
}

.d-grid {
  display: grid;
}

.g-2-col {
  grid-template-columns: 1fr 1fr;
}

.g-full-row {
  grid-column: 1/-1;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow-0 {
  flex-grow: 0;
}

.aic {
  align-items: center;
}
.aib {
  align-items: baseline;
}
.aifs {
  align-items: flex-start;
}
.jcsb {
  justify-content: space-between;
}
.jcfe {
  justify-content: flex-end;
}
.jcc {
  justify-content: center;
}

.display-none {
  display: none !important;
}

.display-block {
  display: block !important;
}

.dib {
  display: inline-block;
}

.align-self-start {
  align-self: flex-start;
}
.align-self-end {
  align-self: flex-end;
}
.align-self-center {
  align-self: center;
}

.v-align-middle {
  vertical-align: middle;
}

figcaption {
  text-align: center;
  color: $black-light;
  font-size: 1.3em;
  @include medium-italic;
  @include font-smoothing;
}

.save--sticky {
  @include invisible;
  display: none;
  position: sticky;
  z-index: 9;
  top: $header-height;
  left: 0;
  right: 0;
  background-color: #404040;
  color: #fff;
  text-align: right;
  padding: 0.8em 1.2em;
  @include transition(opacity);
  p {
    @include inline-block;
    margin: 0 1em 0 0;
  }
  .btn-wrap {
    @include inline-block;
  }
}

.save--sticky[data-visible="true"] {
  @include visible;
  display: block;
}
.save--sticky[data-type="absolute"] {
  position: absolute;
  top: 0;
}

.text-truncate {
  @include truncate();
}

.text-normal {
  font-weight: normal;
}

.text-medium {
  @include medium;
  &--italic {
    font-style: italic;
  }
}

.text-italic {
  @include italic;
}

.text-line-through {
  text-decoration: line-through;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.ff-sans-serif {
  font-family: sans-serif;
}

.bg-red {
  background-color: $red !important;
  &--light {
    background-color: #ff6464 !important;
  }
}

.dropdown-wrap {
  $self: &;
  position: relative;
  margin: 0 auto;
  max-width: 24em;
  .toggle-dropdown-options {
    padding: 1em 3em 1em 1em;
    border: 1px solid $black-lighter;
    border-radius: 3px;
    background-color: #fff;
    position: relative;
    @include transition;
    &.dropdown-active {
      border-radius: 3px 3px 0 0;
    }
    .dropdown-icon {
      flex: 0;
      position: absolute;
      right: 1em;
      @include vertical-align;
      @include transition(opacity);
      i {
        font-size: 1.4em;
      }
    }

    .name-avatar-group {
      display: flex;
      align-items: center;
    }
    .avatar {
      flex-shrink: 0;
      margin-right: 0.8em;
      @include avatar(2.4em, 1.5em);
    }
    .name {
      flex: 1;
      font-size: 1.6em;
      @include truncate;
    }
  }
  &--user {
    width: 20em;
    margin-left: 2em;
    .toggle-dropdown-options,
    .button-option {
      display: flex;
      align-items: center;
      span {
        font-size: 1.6em;
      }
      .avatar {
        width: 3em !important;
        height: 3em !important;
        line-height: 3em;
        border-radius: 50%;
        margin-right: 0.8em;
        img {
          width: 3em !important;
          height: 3em !important;
        }
      }
    }
    .btn--dropdown-options__dropdown {
      width: 100%;
      border-radius: 2px;
      .button-option {
        padding: 1em;
        font-size: 1em;
      }
    }
  }
  &--custom-vars {
    margin: 0;
    position: static;
    .toggle-dropdown-options {
      border: none;
      padding: 0;
    }

    .dropdown-wrap__select {
      top: 0;
      max-height: 22em;
      overflow: hidden;
      overflow-y: auto;
      padding: 0.5em 0;
      background: #eee;
    }
    .option {
      margin-bottom: 0;
      border-radius: 0;
      word-break: break-word;
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: normal;
      &:hover {
        background: $blue;
        font-weight: 400;
        color: white;
      }
    }
  }

  &--leads {
    margin: 0 0 1em;
    .toggle-dropdown-options {
      padding: 0.9em 3em 0.8em 1em;
      min-width: 12.7em;
      background: #646464;
      color: white;
      border: none;
      &.active {
        border-radius: 3px;
      }
      .dropdown-wrap__text {
        color: white;
      }
      &:hover {
        border-color: $black;
      }
    }
  }
  &--leads-date {
    .toggle-dropdown-options {
      max-width: 17.5em;
      border: 1px solid $black-light;
      background: white;
      color: $black;
      @include mq(max, $mq-md) {
        background: none;
        border: none;
        padding-left: 0;
        color: white;
      }
      &,
      &.dropdown-active {
        border-radius: 4px;
      }
    }
    .dropdown-wrap__select {
      min-width: 31em;
      width: auto;
      padding: 1.5em 1em 1em 1em;
      background: #f5f5f5;
      .option {
        background: white;
        padding: 0.7em 1.2em 0.6em 1.2em;
        font-size: 1.5em;
        border: 1px solid #d9d9d9;
        margin-bottom: 0.6em;
        &--active {
          background: $blue;
          color: white;
        }
      }
    }
  }

  &--icons {
    .dropdown-wrap__select {
      width: 25.5em;
    }
    .dropdown-wrap__search {
      width: 100%;
      background: none;
      border-bottom: 1px solid #ddd;
      margin: 0;
      input {
        border: none;
      }
    }
    .toggle-dropdown-options {
      min-width: 6em;
      padding-right: 2em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      border-right: 0;
      span {
        font-size: 1.8em;
        line-height: 1;
      }
    }
    #{$self}__options {
      display: flex;
      flex-wrap: wrap;
      padding: 0.5em;
      max-height: 17em;
      overflow: auto;
    }
    #{$self}__options-category {
      flex: 1 100%;
      margin: 1em 0 0 1em;
      text-transform: uppercase;
      font-size: 1.15em;
      font-weight: 500;
      color: #9b9b9b;
    }

    #{$self}__icon {
      background: transparent;
      border: none;
      font-size: 2em;
      cursor: pointer;
      margin: 0.5em;
      width: 11%;
      padding: 0;
      position: relative;
      &.selected {
        margin: 0;
        width: auto;
        margin-left: 0.9em;
        font-size: 1.3em;
        margin-right: 2.2em;
        span {
          display: flex;
        }
      }
      span {
        background: $blue;
        border-radius: 50%;
        line-height: 1;
        font-size: 0.45em;
        color: white;
        width: 1.5em;
        height: 1.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        top: -5px;
        display: none;
      }
    }
    .loading {
      &:after {
        left: calc(50% - 15px);
        top: calc(50% - 15px);
      }
    }
  }
  &--search {
    max-width: none;
    width: 33em;
    cursor: pointer;
    margin: 0;
    #{$self}__select {
      width: 100%;
      max-height: 40em;
      overflow: hidden;
      overflow-y: auto;
      top: 100%;
      .option {
        font-size: 1.6em;
        margin-bottom: 0;
        @include truncate();
        &:last-child {
          margin-bottom: 0;
        }
        &--link.option {
          margin-bottom: -0.6em;
        }
        span {
          display: inline-block;
          width: 100%;
        }
      }
    }
    #{$self}__select--height-sm {
      max-height: 15em;
    }
    .toggle-dropdown-options {
      font-size: 1.6em;
      padding-bottom: 0.9em;
      .dropdown-icon {
        top: 55%;
        i {
          font-size: 0.9em;
          color: #979797;
        }
      }
    }
  }
  &--search-fixed {
    #{$self}__select {
      max-height: none;
      overflow: visible;
    }
  }
  &--full-width {
    max-width: none;
    width: auto;
    .dropdown-wrap__options {
      .option {
        white-space: normal;
        padding: 1.5em 2em 0.6em 2em;
        font-size: 1em;
        border-radius: 0;
        &:hover {
          background: #e5e5e5;
          font-weight: 400;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #eee;
        }
        span {
          margin-top: 0.2em;
        }
      }
    }
    .dropdown-wrap__select {
      padding: 0;
      box-shadow: none;
      border-radius: 0;
      border-top: 0;
    }
  }
  &--targeting {
    .dropdown-wrap__select {
      width: 100%;
      .option:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &--variables {
    .dropdown-wrap__select {
      width: 100%;
      max-height: 46em;
      overflow: visible;
      .option {
        border-bottom: 1px solid #ddd;
        padding: 1em 1.5em;
        font-size: 1.4em;
        border-radius: 0;
        &--heading {
          font-size: 1.6em;
          font-weight: 500;
          border-bottom: 1px solid #ddd;
          padding: 1em 1.4em;
          margin: 0;
          &:not(:first-child) {
            margin-top: 2em;
          }
        }
      }
    }
    .dropdown-wrap__options {
      overflow: hidden;
      overflow-y: auto;
      max-height: 40em;
    }
    .flyout__add-fields {
      margin-left: 0;
      margin-right: 0;
    }
  }
  &__search {
    background: #f3f3f3;
    padding: 1em;
    margin-top: -1em;
    margin-bottom: 1em;
    position: relative;
    input[type="search"] {
      line-height: 1;
      padding: 0.7em 2em 0.5em 1em;
    }
    button {
      top: 54%;
      transform: translateY(-50%);
      position: absolute;
      right: 1.5em;
      border: none;
      background: none;
      font-size: 1.5em;
      color: #b3b3b3;
    }
    &--integration {
      background: none;
      border-bottom: 1px solid #cfcfcf;
      padding: 0 0 0 3em;
      margin-bottom: 0;
      input[type="search"] {
        border: none;
        padding-left: 0.5em;
      }
      button {
        left: 0.5em;
        right: auto;
      }
    }
    .dropdown-wrap__search-clear {
      position: absolute;
      right: 0.5em;
      transform: none;
      left: auto;
      top: 0.6em;
    }
  }
  &__scrollable {
    max-height: 30em;
    overflow: hidden;
    overflow-y: auto;
  }

  &__hidden {
    padding: 1em;
    background: #eaeaea;
  }

  &__inputs-block {
    display: flex;
    margin-bottom: 1em;
    align-items: center;
    justify-content: space-between;
    input[type="text"] {
      width: 8em;
      font-size: 1.5em;
      padding: 0.6em 1em 0.4em;
    }
    span {
      font-size: 1.5em;
    }
  }

  &__text {
    font-size: 1.4em;
    color: $black-light;
  }

  &__select {
    opacity: 0;
    pointer-events: none;
    width: 21.5em;
    text-align: left;
    padding: 1em 0;
    border-radius: 3px;
    background-color: #fff;
    position: absolute;
    z-index: 9;
    top: calc(100% + 1em);
    border: 1px solid #ddd;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    &--no-box-shadow {
      box-shadow: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &.dropdown-active {
      animation: showDropdown 0.1s ease;
      opacity: 1;
      pointer-events: auto;
    }
    .option {
      padding: 0.5em 1.2em 0.4em 1.2em;
      color: $black;
      border-radius: 3px;
      white-space: nowrap;
      cursor: pointer;
      position: relative;
      font-size: 1.4em;
      display: block;
      @include regular;
      &:hover,
      &.active {
        @include medium;
      }
      &--link {
        margin-top: 0.5em;
        padding-top: 0.7em;
        padding-bottom: 0.7em;
        font-weight: 500;
        border-top: 1px solid #ccc;
        border-radius: 0;
      }
      &.flex {
        display: flex;
      }
      &.text-regular {
        &:hover {
          font-weight: 400;
        }
      }
      &.text-medium {
        font-weight: 500;
      }
      &--sm {
        font-size: 1.4em;
      }
      &--blue {
        background: $blue;
        color: white;
        border-radius: 0;
        span {
          color: white;
        }
      }
      input[type="radio"] {
        display: none;
      }
    }
  }
  &__calculate {
    font-size: 1.2em;
    margin-bottom: 1em;
    text-align: right;
    em {
      color: #a0a0a0;
      font-weight: 500;
      &:after {
        @include spinner(15px);
        margin-left: 0.5em;
        display: inline-block;
      }
    }
  }
}

.custom-progress {
  $self: &;
  display: block;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  -webkit-appearance: none;
  appearance: none;
  height: 6px;
  border-radius: 16px;
  background: #eaeaea;
  &--settings {
    height: 10px;
    flex: 0 12em;
    background: #8c8c8c;
    border-radius: 5px;
    margin: 0 1em 0 0;
    .custom-progress__bar {
      border-radius: 5px;
      background: $brand-primary;
      min-width: 10%;
    }
  }
  &--blue {
    background: rgba(#878787, 0.26);
    .custom-progress__bar {
      background: $blue;
    }
  }
  &--warning {
    .custom-progress {
      &__bar {
        background: #ff6666;
      }
      &__icon {
        color: #ff6666;
        display: inline-block;
      }
    }
  }
  &__bar {
    border-radius: 16px;
    position: relative;
    height: 100%;
    max-width: 100%;
    &--weak {
      background: $red;
      #{$self}__text {
        right: -0.8em;
        &:before {
          content: "Weak";
        }
      }
    }
    &__icon {
      display: none;
    }
    &--average {
      background: #f5a623;
      #{$self}__text:before {
        content: "Average";
      }
    }
    &--strong {
      background: #7ed321;
      #{$self}__text {
        right: 0;
        &:before {
          content: "Strong";
        }
      }
    }
  }
  &__text {
    font-size: 1em;
    color: #4a4a4a;
    position: absolute;
    top: -1.5em;
    right: -1.5em;
  }
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.lead-panel {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  margin-bottom: 1em;
  padding: 1.4em 1.4em 0 1.4em;
  position: relative;
  &--collapse {
    .lead-panel__title {
      margin: -1em -1em 0 -1em;
      padding: 1em;
      border-radius: 5px;
      background: #f8f7f6;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        border-color: #4a4a4a transparent transparent transparent;
        display: inline-block;
      }
    }
    .lead-panel__body {
      height: 0;
      transition: 0.2s;
      padding: 0;
      overflow: hidden;
    }
  }
  &--expanded {
    .lead-panel__title {
      border-radius: 0;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom: 1px solid #d9d8d8;
      &:after {
        transform: rotate(180deg);
      }
    }
    .lead-panel__body {
      height: auto;
      padding: 2em 0px;
      overflow: visible;
    }
  }
  &--user {
    .about--row {
      min-height: 3em;
    }
  }
  &--company {
    .about--row {
      .th {
        align-self: flex-start;
      }
    }
  }
  &--activity {
    .lead-panel__body {
      max-height: 21em;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .step {
      .label {
        time {
          background: white;
        }
      }
    }
  }
  &--source {
    .lead-panel__body {
      .about--row {
        .th {
          width: 13%;
          font-size: 1.6em;
        }
        .td {
          width: 87%;
        }
      }
    }
  }
  .about--row {
    border-bottom: 1px solid #ddd;
    margin-bottom: 1em;
    display: block;
    @include clearfix;
    &:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .th {
      float: left;
      width: 33%;
      padding-right: 1em;
      padding-bottom: 0.5em;
      @include medium;
      font-size: 1.4em;
    }
    .td {
      float: left;
      width: 67%;
      font-size: 1.4em;
      word-break: break-word;
      word-wrap: break-word;
      overflow-wrap: break-word;
      position: relative;
      min-height: 2.072em;
      &--loading {
        &:after {
          content: "";
        }
      }
      &--ok {
        &:before {
          content: "\f00c";
        }
      }
      &:after {
        width: 1.2em;
        height: 1.2em;
        border-radius: 50%;
        transform: translateZ(0);
        border: 1px solid #000;
        border-left: 1px solid transparent;
        animation: spinner 0.5s infinite linear;
        right: 0;
        top: 0;
        position: absolute;
      }
      &:before {
        right: 0;
        top: 0.3em;
        position: absolute;
        font-size: 1.2em;
        color: $green;
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
      }

      input {
        border: none;
        border-bottom: 1px solid transparent;
        padding: 0;
        border-radius: 0;
        &:focus {
          border-bottom: 1px dashed #bbb;
        }
      }
      &__tags {
        font-size: 10px;
      }
    }
    &--variables {
      padding-bottom: 0;
      .th {
        float: none;
        width: 100%;
        padding-right: 0;
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
        margin-bottom: 0.5em;
      }
      .td {
        float: none;
        width: 100%;
      }
    }
    &--center {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-bottom: 0;
      margin: 0;
      padding: 0;
      cursor: pointer;
      display: flex;
      span {
        color: $brand-green;
      }
    }
    a {
      color: $black;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .btn-link {
      padding: 0;
    }
    textarea {
      resize: none;
      max-height: 10em;
      border: none;
      width: 90%;
      border-bottom: 1px solid transparent;
      padding: 0;
      overflow: hidden;
      border-radius: 0;
      &:focus {
        border-bottom: 1px dashed #bbb;
      }
      &.js-auto-size {
        min-height: 22px;
      }
    }
    .remove-variable {
      position: absolute;
      width: 20px;
      height: 20px;
      top: -5px;
      right: -14px;
      cursor: pointer;
      font-size: 1.1em;
      display: none;
    }
  }

  &__bold-text {
    font-weight: 500;
    font-size: 1.4em;
  }
  .custom-select {
    position: absolute;
    width: 100%;
    bottom: 0.5em;
    max-height: 10em;
    overflow-x: hidden;
    overflow-y: auto;
    &__option {
      &:hover {
        background: $blue;
        color: white;
      }
    }
  }
  .lead-avatars {
    position: relative;
    z-index: 2;
    margin: 0;
    display: inline-block;
    margin-right: 2em;
  }
  .avatar {
    @include avatar(6em, 3.6em, 100px);
    border-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(black, 0.5);
    &:not(.avatar--initials) {
      background-color: #fff;
    }
  }
  .avatar--company {
    @include avatar(2.8em, 1em, 100px);
    position: absolute;
    right: 0;
    bottom: -0.5em;
    z-index: 2;
  }
  &__header {
    background-color: $black;
    color: #fff;
    text-align: center;
    margin: -1.4em -1.4em 0;
    padding: 2.8em;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .user-details {
      position: relative;
      z-index: 2;
      text-align: left;
      word-break: break-word;
      word-wrap: break-word;
      overflow-wrap: break-word;
      & + & {
        margin-top: 0.5em;
      }
      span {
        font-size: 1.4em;
        display: inline-block;
        margin: 0 0.1em;
      }
      span.name + span.company:before,
      span.role + span.location:before {
        content: "•";
        display: inline-block;
        margin: 0 0.3em 0 0;
      }
      &.name-company {
        span {
          font-size: 2em;
          @include medium;
        }
      }
    }
  }
  .panel--header {
    position: relative;
    margin-left: -1.4em;
    margin-right: -1.4em;
    margin-bottom: 1.4em;
    padding: 1.5em 1.4em 1.3em;
    background-color: #f8f7f6;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    &:after,
    &:before {
      top: 100%;
      left: 2.2em;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(248, 247, 246, 0);
      border-top-color: #f8f7f6;
      border-width: 0.6em;
      margin-left: -0.6em;
    }
    &:before {
      border-color: rgba(227, 227, 227, 0);
      border-top-color: #e3e3e3;
      border-width: 0.7em;
      margin-left: -0.7em;
    }
  }
  &__body {
    padding: 2em 0;
  }
  &__title {
    @include medium;
    font-size: 1.4em;
    small {
      float: right;
      color: $black-light;
      font-size: 75%;
      @include regular;
      display: inline-block;
      margin-top: 0.2em;
      margin-left: 0.3em;
    }
  }
}

.logic-pulldown {
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  display: inline-block;
  padding: 1.8em 2.4em 1.6em 1.8em;
  cursor: pointer;
  &:after {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-33%);
    right: 1em;
    content: " ";
    border: solid transparent;
    height: 0;
    width: 0;
    pointer-events: none;
    border-color: rgba($black, 0);
    border-top-color: $black;
    border-width: 0.5em;
  }
  .selected-option {
    font-size: 1.8em;
    @include medium;
    @include truncate;
    i {
      margin-right: 0.2em;
    }
  }
  .available-options {
    @include invisible;
    position: absolute;
    z-index: 99;
    left: -1px;
    top: calc(100% + 1px);
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    min-width: 24em;
    &__title {
      font-size: 1.5em;
      font-weight: 500;
      border-bottom: 1px solid #ddd;
      padding: 1em 1.5em;
      margin-top: 0.5em;
      margin-bottom: 0;
    }
  }
  .option {
    border-bottom: 1px solid #ddd;
    padding: 1em;
    padding-bottom: 0.8em;
    font-size: 1.6em;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      background-color: #f6f6f6;
    }
    i {
      margin-left: -0.2em;
      margin-right: 0.2em;
    }
  }
  &--radius {
    border-radius: 22px;
    width: 20em;
    padding-top: 1.3em;
    padding-bottom: 1.2em;
    &:after {
      right: 2em;
    }
    .available-options {
      min-width: 100%;
      border-radius: 5px;
    }
    .option {
      display: block;
      border: none;
    }
    input {
      display: none;
    }
    .logic-pulldown__circle {
      width: 8px;
      height: 8px;
      display: inline-block;
      margin-right: 0.5em;
      border-radius: 50%;
      &[data-color="green"] {
        background: #7fd239;
      }
      &[data-color="red"] {
        background: $red;
      }
    }
    .selected-option {
      font-weight: 400;
      font-size: 1.6em;
    }
  }
  &--small {
    padding: 1.4em 2.5em 1.4em 1.8em;
    min-width: 15em;
    &.wide {
      min-width: 25em;
    }
    .selected-option {
      font-size: 1.6em;
      font-weight: 400;
      .font-roboto & {
        @include regular-roboto;
      }
    }
    .available-options {
      min-width: auto;
      right: -1px;
    }
    .option {
      margin: auto;
      font-weight: 400;
      pointer-events: all;
      &[data-font="roboto"] {
        @include regular-roboto;
      }
      input {
        display: none;
        &:checked + i {
          visibility: visible;
        }
      }
      i {
        visibility: hidden;
      }
    }
  }
  &--short {
    min-width: 7em;
  }
  &--active {
    border-color: #666;
    .available-options {
      @include visible;
    }
  }

  &--blue,
  &--orange {
    border-color: $blue;
    background-color: $blue;
    padding: 1em 3.2em 0.8em 1em;
    &:after {
      border-color: rgba(#fff, 0);
      border-top-color: #fff;
      border-width: 0.5em;
    }
    .selected-option {
      @include regular;
      color: #fff;
    }
    .available-options {
      padding-left: 1px;
      .option {
        padding: 0.8em;
        padding-left: 0.6em;
        padding-bottom: 0.6em;
        font-size: 1.6em;
      }
    }
  }
  &--orange {
    background: #e77f39;
    border-color: #e77f39;
  }
  &--black {
    background: #161616;
    border: none;
    .selected-option {
      color: white;
    }
  }
}

.unread-dot {
  &:before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $red;
    margin-right: 0.5em;
  }
}
.fa-unread-dot {
  position: relative;
  &:after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $red;
    position: absolute;
    right: -3px;
    top: -3px;
    border: 1px solid white;
  }
}

.rotate-3d {
  transform: rotate3d(0, 1, 0, 180deg);
}

.half-width {
  width: 50%;
  & + & {
    margin-left: 5em;
  }
}

.width-50 {
  width: 50%;
}

.help-text-with-arrow {
  position: relative;
  font-family: Caveat;
  margin-left: 11em;
  svg {
    width: 70px;
    height: 12px;
    position: absolute;
    left: -8em;
    top: 1.5em;
  }
  p {
    font-size: 1.8em;
    margin-bottom: 0.5em;
    font-weight: 700;
  }
}

.widget-radio {
  $self: &;
  &__item {
    list-style-type: none;
    position: relative;
    list-style: none;
    display: inline-block;
    vertical-align: middle;
    margin: 0 1em 1em 0;
    font-size: 1em;
    &:first-child {
      svg {
        transform: scaleX(-1);
      }
      #{$self}__text {
        left: 54px;
        right: auto;
      }
    }
  }
  &__text {
    display: block;
    @include regular;
    &--abs {
      position: absolute;
      bottom: 25px;
      right: 54px;
    }
  }
  &__info {
    color: #a2a2a2;
  }
  &__box {
    background: #f9f9f9;
    border: 1px solid #dcdcdc;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    width: 80px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    &--mb {
      margin-bottom: 0.7em;
    }
    &--rounded {
      border-radius: 14px;
    }
  }
  input {
    display: none;
  }
  input:checked ~ label {
    background: white;
    border: 1px solid black;
    svg {
      g {
        fill: $black;
      }
    }
  }
  &__label {
    border: 1px solid #cdcdcd;
    border-radius: 3px;
    padding: 20px 10px 6px 10px;
    cursor: pointer;
    font-size: 1.2em;
    text-align: center;
    display: inline-block;
    @include regular;
    &--centered {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 70px;
      height: 70px; // ie11 fix
    }
  }
  svg {
    width: 78px;
    margin-top: 10px;
    display: block;
  }
  &--sidebar {
    &--pos {
      .widget-radio__item:first-child label {
        padding-right: 20px;
      }
      .widget-radio__item:last-child label {
        padding-left: 20px;
      }
      #{$self}__label {
        padding: 6px;
      }
    }
    #{$self}__label {
      font-weight: 400;
      padding: 1em;
      &[for*="full-height"] {
        padding-right: 0;
      }
    }
    input:checked ~ label {
      border-color: $blue-secondary;
      background: $blue-secondary;
      color: white;
      svg {
        g,
        text,
        rect,
        path {
          fill: white;
        }
        path:not(:first-child) {
          fill: #b3cdf1;
        }
      }
    }
    svg {
      height: 56px;
      width: 74px;
      margin-top: 0;
    }
  }
  &--width {
    #{$self}__label {
      padding-left: 20px;
      margin-bottom: 0;
    }
    svg {
      width: auto;
      height: auto;
      transform: none !important;
      margin: 0;
    }
  }
  &--solid {
    #{$self}__item {
      vertical-align: top;
      margin-right: 0.5em;
    }
    #{$self}__label {
      padding: 0;
      border: none;
      font-size: 1em;
      background: #f1f1f1;
      border-radius: 6px;
      transition: 0.2s;
      &--rounded {
        border-radius: 23px;
      }
    }
    #{$self}__text {
      padding: 1em 2em;
      &--sm,
      &--md,
      &--lg {
        padding-left: 0;
        padding-right: 0;
      }
      &--sm {
        font-size: 1.3em;
        width: 67px;
      }
      &--md {
        font-size: 1.5em;
        width: 82px;
      }
      &--lg {
        font-size: 1.7em;
        width: 89px;
      }
    }
    input:checked ~ label {
      background: $blue-secondary;
      border: none;
      color: white;
    }
  }
  &--button {
    #{$self}__item {
      margin-right: 0.8em;
    }
    #{$self}__label {
      padding: 12px 10px 12px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &--button-shape {
    #{$self}__label {
      min-height: 62px;
    }
  }
  &--button-icon {
    #{$self}__label {
      min-height: 36px;
      min-width: 48px;
      margin-bottom: 0;
    }
  }
}

.pointer-events-none {
  pointer-events: none;
}

.no-max-width {
  max-width: none !important;
}

.no-max-height {
  max-height: none !important;
  &.js-auto-size {
    min-height: 10em !important;
  }
}

.clear-both {
  clear: both;
}

.toggle-wrap {
  &__helper {
    font-style: italic;
    font-weight: 500;
    font-size: 1.2em;
    color: lightgray !important;
    margin-top: 2em;
  }
}

.align-horizontally {
  left: 50%;
  transform: translateX(-50%);
}

.max-width-60 {
  max-width: 60em;
}

.max-width-30 {
  max-width: 30em;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.vis-hidden {
  visibility: hidden;
}

.line-text {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 1px solid #e1e1e1;
    top: 10px;
  }
  p {
    position: relative;
    background: white;
    display: inline-block;
    padding-left: 1em;
    padding-right: 1em;
  }
}

.appointment-schedule {
  background-color: #fff;
  padding: 2.5em 3.2em 1.5em;
  display: inline-block;
  width: 29em;
  border: 1px solid #dcdcdc;
  box-shadow: 0 0 4px 0 rgba(206, 206, 206, 0.25);
  color: #7a7a7a;
  border-radius: 8px;
  position: relative;
  form {
    margin-top: 2em;
    margin-left: -3.2em;
    margin-right: -3.2em;
  }
  div.timezone {
    margin: 1.5em 3.2em 3.2em;
    .pulldown {
      display: inline-block;
      margin: 0 auto;
      width: 100%;
    }
    .pulldown-inner,
    .pulldow-select {
      height: auto;
      padding-left: 0.3125em;
      padding-right: 0.95em;
    }
    select {
      font-size: 1.4em;
    }
    .selected-timezone {
      display: block;
      margin: 0 auto;
      font-size: 1em;
      color: $black-light;
    }
  }
  .loading-calendar-data {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #ffffff;
    opacity: 0.6;
    z-index: 10;
    .spinner {
      position: absolute;
      top: calc(50% - 1em);
      left: calc(50% - 1em);
      border: 1px solid #333333 !important;
      border-left: 1px solid transparent !important;
      @include spinner(2em, 1px);
      margin: 0 auto 1em;
    }
  }
  .day-select-control {
    position: relative;
  }
  .day-select {
    position: relative;
    text-align: center;
    margin-bottom: 1.6em;
    margin-right: 3.2em;
    margin-left: 3.2em;
    a {
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      svg {
        fill: $brand-primary;
      }
    }
    a.previous {
      left: 0;
    }
    a.next {
      right: 0;
    }
    a.disabled {
      opacity: 0.15;
    }
    .day-label {
      color: $black-light;
      font-size: 1.6em;
      @include uppercase;
    }
  }
  .day-scroll {
    position: relative;
    &.day-scroll--active {
      &:before {
        position: absolute;
        z-index: 2;
        content: "";
        left: -1em;
        right: -1em;
        top: 0;
        height: 1em;
        background-image: linear-gradient(rgba(#fff, 1), rgba(#fff, 0));
        pointer-events: none;
      }
      &:after {
        position: absolute;
        z-index: 2;
        content: "";
        left: -1em;
        right: -1em;
        bottom: 0;
        height: 1em;
        background-image: linear-gradient(rgba(#fff, 0), rgba(#fff, 1));
        pointer-events: none;
      }
    }
  }
  .day-scroll--active {
    fieldset.times {
      padding-bottom: 1.75em;
      max-height: 18em;
      overflow: auto;
    }
  }
  fieldset.times,
  fieldset.fallback {
    margin: 0;
    padding-top: 1.5em;
    margin-top: 1em;
    label {
      font-size: 1.4em;
      border-radius: 2px;
      border: 1px solid $brand-primary;
      padding: 0.5em 0 0.3em;
      position: relative;
      cursor: pointer;
      text-align: center;
      margin: 0 0 0.25em;
      background-color: #fff;
      color: $brand-primary;
      font-weight: 400;
      small {
        font-size: 70%;
      }
    }
    input[type="checkbox"],
    input[type="radio"] {
      display: none;
    }
    label.selected {
      background-color: $brand-primary;
      color: #fff;
    }
    input:disabled + label {
      cursor: not-allowed;
      opacity: 0.25;
    }
    input:checked:disabled + label {
      opacity: 1;
    }
  }
  fieldset.fallback {
    padding-top: 0;
    margin-bottom: -1em;
  }
  fieldset.textarea {
    margin: 1em 0 2em;
  }
  a.show-more-times {
    display: block;
    text-align: center;
    text-decoration: none;
    color: $black-lighter;
    padding: 1em;
    font-size: 1.3em;
  }
  fieldset.propose-different-time {
    margin: 0 3.2em 1.6em;
    label {
      font-size: 1.6em;
      margin-bottom: 0;
      color: #000;
      font-weight: 500;
    }
    textarea {
      font-size: 1.85em;
      border: none;
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
      max-width: none;
      resize: none;
      height: auto;
      background-color: transparent;
      border-bottom: 1px solid $black;
    }
  }
  fieldset.capture-email-address {
    margin: 1em 3.2em 1.6em;
    label {
      font-size: 1.6em;
      margin-bottom: 0;
      color: #000;
      font-weight: 500;
    }
    input[type="email"] {
      font-size: 1.85em;
      border: none;
      border-radius: 0;
      padding: 0.8em 0 0.6em;
      max-width: none;
      resize: none;
      height: auto;
      background-color: transparent;
      border-bottom: 1px solid $black;
    }
  }
  .schedule-demo__confirmation {
    text-align: center;
    padding: 1.6em;
    margin-top: -3.2em;
    position: relative;
    &.summary-panel {
      padding-top: 3.4em;
      .avatar-wrap {
        margin: 0 auto;
        max-width: 4.3em;
        position: relative;
        .check {
          position: absolute;
          z-index: 2;
          left: 0;
          top: 0;
          background-color: $blue;
          color: #fff;
          width: 1.6em;
          height: 1.6em;
          line-height: 1.6em;
          border-radius: 100px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .avatar {
        @include avatar(4.3em, 1.6em, 100px);
      }
    }
    .heading {
      text-transform: uppercase;
      font-size: 1.4em;
      margin: 0 2em 1.5em;
      font-weight: 500;
    }
    .img--calendar {
      margin: 0 auto;
      max-width: 5em;
    }
    .chosen-time {
      font-size: 1.6em;
      margin-top: 1em;
      margin-bottom: 1em;
      color: #000;
      font-weight: 500;
    }
    .summary {
      margin-top: 1em;
      text-align: center;
      font-size: 1.6em;
      overflow-wrap: normal;
      word-break: normal;
      hyphens: none;
    }
    .link--back {
      position: absolute;
      left: 1em;
      top: 1em;
      font-size: 1.6em;
      text-decoration: none;
      cursor: pointer;
      color: $black;
      &:hover {
        color: $brand-green;
      }
    }
    .link--change {
      text-transform: uppercase;
      font-size: 1.4em;
      color: $black-light;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $brand-green;
      }
    }
  }
}

.appointment-panel {
  border: 1px solid #dbdbdb;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background: white;
  display: flex;
  max-width: 81em;
  margin: 4em auto;
  &--sm {
    max-width: 40em;
  }

  &__title {
    font-weight: 500;
    font-size: 1.2em;
    color: #a5a5a5;
    letter-spacing: 1px;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 1em;
    text-transform: uppercase;
  }
  &__sidebar {
    background: #f8f8f8;
    padding: 3em 2.5em 3em 3em;
    flex-basis: 40%;
    .appointment-table {
      tr:first-child {
        border-top: 1px solid #e3e3e3;
      }
      td:first-child {
        width: 8em;
      }
    }
  }
  &__content {
    padding: 3em 2.5em 3em 3em;
    flex-basis: 60%;
    flex-grow: 1;
  }
}

.appointment-table {
  font-size: 1.5em;
  width: 100%;
  tr {
    border-bottom: 1px solid #e3e3e3;
  }
  tr.no-border {
    border-width: 0px !important;
  }
  td {
    padding: 1em 0;
    &:first-child {
      font-weight: 500;
      width: 8.2em;
    }
  }
  .avatar {
    border-radius: 20px;
    width: 2em !important;
    height: 2em !important;
    line-height: 2em;
    img {
      width: 2em !important;
      height: 2em !important;
    }
  }
}

.resize-none {
  resize: none;
}

// LOOPS
@for $i from 8 through 30 {
  .fz-#{$i} {
    font-size: #{$i * 0.1}em;
  }
}

@for $i from 1 through 5 {
  .line-height-#{$i} {
    line-height: #{$i}em;
  }
}

@for $i from 0 through 10 {
  .margin-#{$i} {
    margin: #{$i}em !important;
  }
  .padding-#{$i} {
    padding: #{$i}em !important;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}em;
  }
  .mt-#{$i} {
    margin-top: #{$i}em;
  }
  .ml-#{$i} {
    margin-left: #{$i}em;
  }
  .mr-#{$i} {
    margin-right: #{$i}em;
  }
  .minus-mt-#{$i} {
    margin-top: -#{$i}em;
  }
  .minus-mb-#{$i} {
    margin-bottom: -#{$i}em;
  }
  .minus-ml-#{$i} {
    margin-left: -#{$i}em;
  }
  .pb-#{$i} {
    padding-bottom: #{$i}em;
  }
  .pt-#{$i} {
    padding-top: #{$i}em;
  }
  .pl-#{$i} {
    padding-left: #{$i}em;
  }
  .pr-#{$i} {
    padding-right: #{$i}em;
  }
  .opacity-#{$i}0 {
    opacity: #{$i * 0.1};
  }
  .zindex-#{$i} {
    z-index: #{$i};
  }
  .min-width-#{$i} {
    min-width: #{$i}em;
  }
  .right-#{$i} {
    right: #{$i}em;
  }
  .left-#{$i} {
    left: #{$i}em;
  }
}

.min-width-none {
  min-width: 0 !important;
}

.border-none {
  border: none !important;
}

.border-top-none {
  border-top: none !important;
}

.border-red {
  border-color: $red !important;
}

.mb-05 {
  margin-bottom: 0.5em;
}

.mt-05 {
  margin-top: 0.5em;
}

.mt-1point5 {
  margin-top: 1.5em;
}

.mb-1point5 {
  margin-bottom: 1.5em;
}

.mr-1point5 {
  margin-right: 1.5em;
}

.ml-1point5 {
  margin-left: 1.5em;
}

.mr-025 {
  margin-right: 0.25em;
}

.mr-05 {
  margin-right: 0.5em;
}

.ml-03 {
  margin-left: 0.3em;
}
.ml-05 {
  margin-left: 0.5em;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.px-1 {
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.line-height-1point2 {
  line-height: 1.2;
}

.line-height-1point5 {
  line-height: 1.5;
}

.rotateY-180 {
  transform: rotateY(180deg);
}

.helper-panel {
  padding: 4.2em 6em 2.3em 6em;
  color: white;
  position: relative;
  border-radius: 5px;
  margin-bottom: 4em;
  background-color: #182e59;
  background-repeat: no-repeat;
  background-size: cover;
  display: none;
  &--multiple-bots,
  &--one-bot {
    background-position: center right;
    @include mq(max, $mq-md) {
      padding: 3em 3em 4em;
      text-align: center;
      background: #17284e;
      > p {
        margin-bottom: 1em;
      }
      svg {
        display: none;
      }
      .helper-panel__content {
        margin: auto;
        p {
          line-height: 1.4;
          margin-bottom: 1.5em;
        }
      }
      .btn.helper-panel__hide-btn {
        bottom: 1.5em;
        top: auto;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 500;
      }
      a {
        color: white;
        background-color: $brand-primary;
        border-color: darken($brand-primary, 10%);
        text-decoration: none;
        padding: 0.6em 1.6em 0.5em;
        border-radius: 4px;
        &:hover {
          color: white;
          background-color: darken($brand-primary, 10%);
        }
      }
    }
    svg {
      width: auto;
      height: auto;
      margin-left: 5em;
    }
  }
  &--multiple-bots {
    background-image: url(../images/bots/multiple-bots@2x.jpg);
    svg {
      margin-right: 10%;
      margin-top: -5em;
    }
  }
  &--one-bot {
    background-image: url(../images/bots/one-bot@2x.jpg);
    svg {
      margin-right: 1%;
      margin-top: -4.2em;
      margin-bottom: -2em;
    }
  }
  &--calendar {
    background-image: url(../images/docs/calendar-helper-bg@2x.jpg);
  }
  &--calendar-connected {
    background-image: url(../images/docs/calendar-helper-bg-connected@2x.jpg);
  }
  > p {
    font-size: 2.2em;
    font-weight: 500;
  }
  &__content {
    width: 39em;
    p {
      font-size: 1.5em;
      line-height: 1.2;
    }
  }
  &__hide-btn.btn {
    color: white;
    position: absolute;
    text-decoration: underline;
    top: 1.5em;
    right: 1.5em;
    padding: 0;
  }
  &--branding {
    background-image: url(../images/branding/branding-banner.jpg);
    min-height: 400px;
    padding-top: 11em;
    > p {
      font-size: 2.8em;
      margin-bottom: 0.4em;
    }
    .helper-panel__content p {
      font-size: 1.8em;
      line-height: 1.44;
    }
  }
  &--billing {
    padding-top: 5.5em;
    padding-bottom: 5.5em;
    overflow: hidden;
    background-position-y: bottom;
    background-position-x: -27em;
    @include mq(min, 1100px) {
      background-position-x: right;
    }
  }
  &--billing-free {
    background-image: url(../images/billing/free-banner.png);
    @include retina() {
      background-image: url(../images/billing/free-banner@2x.png);
    }
  }
  &--billing-appsumo {
    background-image: linear-gradient(135deg, #e79721 10%, #fdf1e0);
    .helper-panel__content {
      width: 55em;
    }
  }
  &--billing-starter {
    background-image: url(../images/billing/starter-banner.png);
    @include retina() {
      background-image: url(../images/billing/starter-banner@2x.png);
    }
  }
  &--billing-professional {
    background-image: url(../images/billing/professional-banner.png);
    @include retina() {
      background-image: url(../images/billing/professional-banner@2x.png);
    }
  }
  &--warning {
    background-image: linear-gradient(-48deg, #f57a51 0%, #d11330 100%);
    padding: 7em 10em 6em 8em;
    .helper-panel {
      &__cols {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include mq(max, $mq-lg) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      &__content {
        @include mq(max, $mq-lg) {
          margin-bottom: 4em;
        }
        p {
          font-size: 1.6em;
          line-height: 1.5;
        }
      }
    }
  }
  &--billing-cancelled {
    background-image: linear-gradient(136deg, #f2994a 0%, #f2c94c 100%);
    text-align: center;
  }
  &--landing-pages {
    background-image: url(../images/landing-pages/custom-domain-banner.jpg);
    min-height: 225px;
    padding-top: 4em;
    > p {
      font-size: 2em;
      margin-bottom: 0.3em;
    }
    .helper-panel__content p {
      font-size: 1.6em;
      line-height: 1.625;
    }
  }
  &__title {
    font-size: 2.8em;
    i {
      font-size: 0.8em;
      margin-right: 0.2em;
    }
  }
  &__list {
    list-style-type: none;
    li {
      margin-bottom: 0.8em;
      i {
        color: #ffc427;
        margin-right: 0.5em;
        font-size: 0.9em;
      }
    }
  }
}

.button-svg {
  background: transparent;
  padding: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    width: auto;
    height: auto;
  }
}

.input-transparent {
  background: transparent !important;
  border: none !important;
  padding-left: 0 !important;
  font-weight: 500 !important;
  padding-top: 0 !important;
  font-size: 2.2em !important;
  padding-bottom: 0.5em !important;
  &--external-api {
    border-bottom: 1px dashed #868e96 !important;
    padding-bottom: 0.2em !important;
    width: auto !important;
    border-radius: 0 !important;
    padding-right: 1.2em !important;
    & ~ label {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.switch {
  margin-bottom: 3em;
  &__toggle {
    border-radius: 1em;
    font-size: 20px;
    height: 1em;
    width: 2.2em;
    background: #bdb9a6;
    border-radius: 1em;
    display: inline-flex;
    align-items: center;
    position: relative;
    margin-left: 0.5em;
    transition: 0.2s;
    &--active {
      background: $blue;
    }
    span {
      height: 0.8em;
      width: 0.8em;
      border-radius: 1em;
      background: #fff;
      box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
      display: inline-block;
      position: absolute;
      right: 3px;
      transition: all 300ms;
    }
    input {
      display: none;
      &:checked + span {
        right: auto;
        left: 3px;
      }
    }
  }
}

.textarea-code {
  background: #494949;
  color: white;
  font-family: $font-family-monospace;
  font-size: 1.4em !important;
  padding: 2em 1.5em;
  min-height: 13em !important;
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.ov-h {
  overflow: hidden;
}

.ov-y-auto {
  overflow-y: auto;
}

.gtm-helper {
  display: flex;
  align-items: center;
  figure {
    margin-right: 1.7em;
  }
  p {
    color: #8b8b8b;
  }
}

#HW_frame_cont {
  right: 10em !important;
  left: auto !important;
  @include mq(min, $mq-md) {
    right: 20em !important;
  }
  &.HW_visible {
    height: 521px !important;
    #HW_frame {
      height: 521px !important;
    }
  }
}

.faq-links {
  p {
    margin-bottom: 0.5em;
  }
  a {
    color: $black;
  }
}

.object-tip {
  background: rgba($blue, 0.1);
  border: 1px solid rgba($blue, 0.3);
  padding: 1.1em 1.5em 1em 1em;
  color: $blue;
  display: inline-block;
  border-radius: 3px;
  font-size: 1.4em;
  line-height: 1;
  &--new {
    background: #cfdaf7;
    border: none;
    border-radius: 6px;
    padding: 1.3em;
    font-size: 1.6em;
    color: $blue-new;
  }
  &--purple {
    background: #ecd6f6;
    color: #a033d3;
  }
  &--lead {
    display: flex;
    align-items: flex-start;
    margin-left: -0.5em;
    margin-right: -0.5em;
    padding: 1em;
    font-size: 1.3em;
    p {
      margin-bottom: 0;
    }
  }
  &--danger {
    background: rgba($red, 0.3);
    border-color: $red;
    color: $red;
  }
  &--danger-light {
    background: #fceaea;
  }
  &--lg {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  }
  &--blue-border {
    border-color: $blue-secondary;
  }
  &__img {
    width: 8.5%;
    margin-right: 0.5em;
  }
  p {
    font-size: 1em;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}

.lc-switch {
  margin-top: auto;
  padding: 3.5em 1.5em 0;
  &__label {
    display: flex;
    justify-content: center;
  }
  &__radio {
    width: 4em;
    height: 1.6em;
    display: inline-block;
    border-radius: 8px;
    background: black;
    position: relative;
    margin-right: 1em;
    cursor: pointer;
  }
  &__circle {
    background: #9b9b9b;
    width: 2em;
    height: 2em;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;
  }
  &__input {
    display: none;
    &:checked + .lc-switch__radio {
      .lc-switch__circle {
        background: #68bf67;
        transform: translate(100%, -50%);
      }
    }
    &:checked ~ .lc-switch__text {
      .lc-switch__on {
        display: inline-block;
      }
      .lc-switch__off {
        display: none;
      }
    }
  }
  &__text {
    font-size: 1.6em;
    font-weight: 500;
    color: white;
  }
  &__on {
    color: #68bf67;
    display: none;
  }
  &__off {
    color: $red;
  }
}

.text-dec-none {
  text-decoration: none;
}

.text-dec-under {
  text-decoration: underline;
}

.inline-form-row {
  input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
}

.w-auto {
  width: auto !important;
}

.h-auto {
  height: auto;
}

.flex-1 {
  flex: 1 auto;
}

.flex-50 {
  flex: 1 50%;
}

.flex-100 {
  flex: 1 100%;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.login-form {
  &__header {
    text-align: center;
    h2 {
      font-weight: 400;
      font-size: 2.8em;
      text-align: center;
      margin-bottom: 0.5em !important;
    }
    h4 {
      @include font-smoothing;
      font-size: 1.8em;
      color: #696969;
      margin: 0.4em 0 1em;
      font-weight: normal;
    }
  }
  form {
    position: relative;
    margin-top: 3em;
    margin-left: auto;
    margin-right: auto;
    .form-help {
      margin-top: 0.5em;
    }
    fieldset {
      margin: 0 auto;
      margin-bottom: 2em;
      position: relative;
      max-width: 34em;
      input[type="text"],
      input[type="url"] {
        max-width: none;
        @include input-placeholder {
          color: $black-lighter;
        }
      }
      p {
        margin-bottom: 0;
        &:empty {
          display: none;
        }
      }
    }
    input {
      &.material-input:focus,
      &.material-input.active {
        border-color: #333;
      }
      &:focus + label,
      &.active + label,
      &:not(:empty) + label {
        top: -0.5em;
        transform-origin: left bottom;
        transform: scale(0.77);
        background: white;
        padding-left: 0.5em;
        padding-right: 0.5em;
        left: 0.5em;
      }
    }
    input[type="checkbox"] {
      box-shadow: none;
    }
    label:not(.check) {
      font-weight: 400;
      color: #9b9b9b;
      position: absolute;
      top: 35%;
      transition: 0.2s;
      left: 0.9em;
      margin-bottom: 0;
      line-height: 1;
      font-size: 1.8em;
      pointer-events: none;
      a {
        color: inherit;
      }
    }
    .check {
      font-size: 1.8em;
      color: #696969;
      a {
        color: inherit;
      }
    }
    .dropdown-wrap {
      max-width: none;
      &.chosen {
        & + label {
          display: inline-block;
          top: -10%;
          left: 0.5em;
          font-size: 1.3em;
          background: white;
          padding: 0em 0.5em;
        }
      }
      .toggle-dropdown-options {
        padding: 1.3em 3em 1.3em 1.5em;
      }
      .dropdown-wrap__select {
        top: 100%;
        left: 0;
        right: 0;
        box-shadow: none;
        border: 1px solid #cfcfcf;
      }
      .option {
        font-size: 1.5em;
      }
      & + label {
        display: none;
      }
    }
  }
}

.login-achievements {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  &__item:not(:last-child) {
    margin-bottom: 5em;
  }
  &__title {
    font-weight: 700;
    font-size: 1.6em;
    margin-bottom: 0.2em;
    margin-top: 0.5em;
    text-transform: uppercase;
  }
  &__text {
    font-weight: 500;
    font-size: 1.4em;
  }
  svg {
    width: auto;
    height: auto;
  }
}
.conversation-activity {
  &__date {
    text-transform: uppercase;
    font-size: 1.2em;
  }
  &__steps {
    margin-left: 2.5em;
    margin-top: 1.5em;
  }
  .step {
    position: relative;
    padding-bottom: 2em;
    border-left: 2px solid rgba($brand-primary, 0.5);
    padding-left: 1.5em;
    margin-bottom: 0.4em;
    min-height: 4em;
    &:first-child {
      .time {
        padding-top: 0;
      }
    }
    &.step--complete {
      &.step--current {
        border-left-style: dotted;
      }
    }
    &.step--future {
      border-left-style: dotted;
      &:before {
        background-color: #fff;
      }
    }
    &.step:last-of-type {
      padding-bottom: 0;
      border-left-color: transparent;
    }
    .label {
      @include medium;
      font-size: 1.5em;
    }
    time {
      font-size: 0.8em;
      position: absolute;
      left: -2.3em;
      background: #f1f1f1;
      top: 0em;
      color: $brand-primary;
      padding-bottom: 0em;
      padding-top: 0.1em;
      font-weight: 700;
    }
    small {
      font-size: 0.87em;
      color: $black-light;
      font-weight: normal;
      word-break: break-word;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
}

.image-icon-check {
  position: relative;
  i {
    position: absolute;
    color: $brand-primary;
    border-radius: 50%;
    background: white;
    left: -0.3em;
    top: -0.1em;
  }
}

.mobile-only {
  display: block !important;
  @include mq(min, $mq-md) {
    display: none !important;
  }
}

.desktop-only {
  display: none !important;
  @include mq(min, $mq-md) {
    display: block !important;
  }
}

.page-tabs-out {
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 3em;
  padding-top: 2em;
  background: white;
  &--transparent {
    background: transparent;
  }
  &--sticky {
    position: sticky;
    padding-top: 2.5em;
    position: sticky;
    top: $header-height;
    background: white;
    z-index: 3;
  }
  &--apps {
    background: #252525;
    @include mq(max, $mq-md) {
      transition: 0.3s top;
    }
    .page-tabs__item {
      &,
      &:first-child {
        margin-right: 2.2em;
      }
      a {
        padding-left: 0;
        padding-right: 0;
        color: white;
        opacity: 0.7;
        min-width: auto;
        font-weight: 400;
        &.active {
          opacity: 1;
          color: white;
          border-color: rgba(255, 255, 255, 0.5);
          border-width: 5px;
          font-weight: 500;
        }
      }
    }
  }
  .container {
    padding-top: 0;
    padding-bottom: 0;
  }
  .page-tabs {
    margin-bottom: -1px;
    border-color: #d0d0d0;
    &__item {
      a {
        border-width: 3px;
        &.active {
          font-weight: 500;
        }
      }
      &:first-child {
        margin-right: 1.5em;
      }
    }
  }
}

.page-tabs {
  $self: &;
  display: flex;
  list-style-type: none;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 3em;
  &--mobile {
    @include mq(min, $mq-md) {
      display: none;
    }
    .page-tabs__item {
      flex: 1 auto;
      a {
        width: 100%;
      }
    }
  }
  .body--docs & {
    position: sticky;
    padding-top: 4em;
    top: 5em;
    background: white;
    z-index: 1;
    margin-left: -1px;
    margin-top: -4em;
  }
  &__item {
    &:first-child {
      a {
        padding-left: 0;
        padding-right: 0;
      }
    }
    a {
      color: $black;
      display: inline-block;
      padding-bottom: 1em;
      padding-left: 1em;
      padding-right: 1em;
      transition: color 0.2s;
      text-decoration: none;
      margin-bottom: -1px;
      border-bottom: 3px solid transparent;
      min-width: 7em;
      text-align: center;
      &.active {
        color: #0f47d7;
        border-color: #0f47d7;
        border-width: 3px;
        font-weight: 500;
      }
    }
  }
  &__item-error {
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    vertical-align: top;
    color: white;
    background: $red;
    border-radius: 50%;
    font-size: 0.7em;
    min-width: 1.5em;
    padding-left: 0.1em;
    padding-right: 0.1em;
    height: 1.5em;
    font-weight: 500;
  }
}

.list-style-none {
  list-style: none;
}

.vjs-swarmify-play-button {
  svg {
    width: auto;
  }
}

.word-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.faq-links {
  a {
    font-size: 1.6em;
    color: #333;
    margin-bottom: 0.5em;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 5px;
    display: flex;
    padding: 1em 1.5em;
    text-decoration: none;
    &:hover {
      border-color: $blue-secondary;
    }
    &:after {
      content: "→";
      font-family: $font-family-sans-serif;
      margin-left: auto;
    }
  }
}
.dir-rtl {
  direction: rtl;
}
.list-style-type-none {
  list-style-type: none;
}
.border-width-2 {
  border-width: 2px !important;
}
.js-rule-pattern.border-width-2 {
  border-width: 2px !important;
}
.js-rule-pattern.border-red {
  border-color: red !important;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  &::before {
    display: block;
    content: "";
    padding-bottom: 56.25%;
  }
  .smartvideo-player {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    border: 0;
  }
}

.favourite-icon-stack {
  &:hover {
    .fa-circle {
      color: rgba(247, 181, 0, 0.1);
    }
  }
  .fa-circle {
    color: #fff;
  }
  .fa-star {
    color: #f7b500;
  }
}

.agent-roles {
  $self: &;
  margin-top: 8em;
  .getting-started__content & {
    margin-top: 2em;
    #{$self}__list {
      flex-wrap: wrap;
    }
    #{$self}__item {
      flex-basis: 100%;
      margin-bottom: 2em;
      @include mq(min, $mq-md) {
        flex-basis: 47%;
      }
    }
  }
  &__list {
    list-style-type: none;
    display: flex;
    align-items: center;
  }
  &__item {
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 5px;
    padding: 2em;
    min-height: 15em;
    font-size: 1em;
    flex-basis: 20%;
    @include mq(min, $mq-md) {
      &:not(:last-child) {
        margin-right: 2em;
      }
    }
  }
  &__icon {
    font-size: 1.6em;
    color: $black;
    margin-bottom: 1em;
  }
  &__title {
    font-size: 1.6em;
    font-weight: 500;
    margin-bottom: 0.5em;
  }
  &__desc {
    font-size: 1.4em;
  }
}

.custom-checkbox {
  input[type="checkbox"] {
    display: none;

    &:checked + .custom-checkbox__label {
      div {
        background-color: $green;
        left: calc(100% - 2.2em);
      }
    }
  }
  &__label {
    display: inline-block !important;
    vertical-align: middle;
    cursor: pointer;
    background-color: #e5e5e5;
    border-radius: 10em;
    margin-right: 1em;
    position: relative;
    width: 4em;
    height: 1.6em;
    font-size: 1em;
    @include transition(background-color);
    span {
      @include offscreen;
    }
    &:hover {
      background-color: darken(#e5e5e5, 5%);
    }
    div {
      border-radius: 10em;
      width: 2.2em;
      height: 2.2em;
      background-color: #a2a2a2;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      @include transition;
    }
  }

  .toggle-label {
    @include inline-block;
    @include medium;
    @include font-smoothing;
    font-size: 1.5em;
  }
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.grid-min-max {
  grid-template-columns: minmax(0, min-content);
}

.grid-min-max-half {
  grid-template-columns: repeat(auto-fill, minmax(0, 50%));
}

.gap-1 {
  gap: 1em;
}

.gap-2 {
  gap: 2em;
}
.vertical-align-middle {
  vertical-align: middle;
}