html {
    font-size: 62.5%;
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    color: $black;
    @include regular;
    // @include font-smoothing;
}

body {
    font-size: 80%;
    @include mq(min, $mq-lg) {
        font-size: 90%;
    }
    @include mq(min, $mq-xl) {
        font-size: 100%;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.1;
}

h1 {
    font-size: 4em;
    margin-bottom: 0.5em;
}
h2 {
    font-size: 3.2em;
    margin-bottom: ($spacer * 0.5);
}
h3 {
    font-size: 2.8em;
    margin-bottom: ($spacer * 0.5);
}
h4 {
    font-size: 2.4em;
    margin-bottom: ($spacer * 0.5);
}
h5 {
    font-size: 2em;
    margin-bottom: ($spacer * 0.5);
}
h6 {
    font-size: 1.6em;
    margin-bottom: ($spacer * 0.5);
}

h1,
h2,
h3 {
    @include light;
}

h4,
h5,
h6 {
    @include medium;
}

th,
strong,
b {
    @include bold;
}

em,
i {
    @include italic;
}

strong,
b {
    em,
    i {
        @include bold-italic;
    }
}

em,
i {
    strong,
    b {
        @include bold-italic;
    }
}

.lead {
    font-size: 2em;
}

p,
.p,
li {
    font-size: 1.6em;
    p,
    .p,
    li {
        font-size: 1em;
    }
}

th,
td {
    p,
    .p,
    li {
        font-size: 1em;
    }
}

nav li {
    font-size: 1em;
}

p,
.p {
    margin-bottom: 1em;
    line-height: 1.4;
}

ul.styled,
ol.styled {
    margin-left: ($spacer * 2);
    margin-bottom: $spacer;
}

ul li li {
    font-size: 1em;
}

hr {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    border: 0;
    border-top: 1px solid #ddd;
}

small,
.small {
    font-size: 80%;
}

small small {
    font-size: 100%;
}

button,
input,
optgroup,
select,
textarea {
    @include regular;
    .font-roboto .continually-timeline & {
        @include regular-roboto;
    }
}

pre,
code {
    font-family: $font-family-monospace;
}

.handwritten {
    @include caveat;
}

// Break long strings to prevent x-scrolling
.word-wrap {
    word-break: break-word;
    word-wrap: break-word;
}

.hyphens {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

.page-header {
    font-size: 4em;
    margin-top: 0.5em;
    margin-bottom: 1em;
    @include light;
    &--mb-sm {
        margin-bottom: 0.4em;
    }
    & + p.lead {
        margin-top: -1.3em;
        @include light;
        max-width: 36em;
    }
    figure,
    span {
        @include inline-block;
        margin-right: 0.25em;
    }
}

.module-header {
    font-size: 2em;
    margin-bottom: $spacer;
    @include medium;
}

.section-header {
    font-size: 1.4em;
    margin: 0;
    @include medium;
    @include uppercase;
}

.text {
    &--danger {
        color: $error;
    }
}
