//////////////////////////////////////////////////

@use "sass:math";

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin reverse-font-smoothing {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}

@mixin debug {
  outline: 1px solid red !important;
}

//////////////////////////////////////////////////

@mixin light {
  font-weight: 300;
}

@mixin light-roboto {
  font-family: Roboto, $font-family-sans-serif;
  font-weight: 300;
}

@mixin light-italic {
  font-weight: 300;
  font-style: italic;
}

@mixin regular {
  font-family: "MundoSansPro", $font-family-sans-serif;
  font-weight: 400;
}

@mixin regular-roboto {
  font-family: Roboto, $font-family-sans-serif;
  font-weight: 400;
}

@mixin italic {
  font-style: italic;
  font-weight: 400;
}

@mixin medium {
  font-weight: 500;
}

@mixin medium-roboto {
  font-family: Roboto, $font-family-sans-serif;
  font-weight: 500;
}

@mixin medium-italic {
  font-weight: 500;
  font-style: italic;
  @include font-smoothing;
}

@mixin bold {
  font-weight: 700;
  @include font-smoothing;
}

@mixin bold-roboto {
  font-family: Roboto, $font-family-sans-serif;
  font-weight: 700;
  @include font-smoothing;
}

@mixin bold-italic {
  font-weight: 700;
  font-style: italic;
  @include font-smoothing;
}

@mixin caveat {
  font-family: "Caveat", cursive;
}

//////////////////////////////

@mixin avatar($size: 2.4em, $fontsize: 1.2em, $border-radius: 2px) {
  width: $size !important;
  height: $size !important;
  line-height: #{$size};
  border-radius: $border-radius;
  &:before {
    font-size: $fontsize;
  }
  img {
    width: #{$size} !important;
    height: #{$size} !important;
    border-radius: $border-radius;
  }
}

//////////////////////////////////////////////////

// https://codepen.io/jakob-e/pen/WvrJwN
@mixin aspect-ratio($arglist... /*$ratio or $width, $height*/) {
  $map: keywords($arglist);
  $height: map-get($map, height) or nth-value($arglist, 2);
  $width: map-get($map, width) or nth-value($arglist, 1);
  $ratio: map-get($map, ratio) or
    if($width and $height, math.div($width, $height), nth-value($arglist, 1)) or 1;
  $padding: math.div(1, $ratio) * 100%;
  &::before,
  &::after {
    content: "";
    display: table;
    clear: both;
    width: 1px;
    margin-left: -1px;
  }
  &::before {
    float: left;
    padding-bottom: $padding;
  }
}

// Helper function
// Return null rather than throwing an error if index is outside list range.
@function nth-value($list, $index) {
  @return if(length($list) >= $index, nth($list, $index), null);
}

//////////////////////////////////////////////////

@mixin wh($width: 16px, $height: $width) {
  width: $width;
  height: $height;
}

//////////////////////////////////////////////////

@mixin transition($prop: all) {
  transition: $prop 0.2s ease-in-out;
}

//////////////////////////////////////////////////

@mixin quill-emoji-reset {
  width: auto;
  height: auto;
  text-indent: 0;
  padding: 0;
  background-image: none;
}

//////////////////////////////////////////////////

@mixin flag($fontsize: 1.2em, $color: $white, $bgc: $brand-primary) {
  font-size: $fontsize;
  color: $color;
  @include uppercase;
  background-color: $bgc;
  line-height: 1;
  padding: 0.3em 0.4em;
  text-align: center;
}

//////////////////////////////////////////////////

@mixin spinner($size: 30px, $border: 2px, $color: #aaa) {
  width: $size;
  height: $size;
  border-radius: 50%;
  content: "";
  transform: translateZ(0);
  border: $border solid $color;
  border-left: $border solid transparent;
  animation: spinner 0.5s infinite linear;
}

//////////////////////////////////////////////////

@mixin uppercase {
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

//////////////////////////////////////////////////

@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

//////////////////////////////////////////////////

@mixin circle($size: 32px) {
  width: $size;
  height: $size;
  line-height: $size;
  text-align: center;
  @include inline-block;
  border-radius: 50%;
}

//////////////////////////////////////////////////

@mixin vertical-align {
  top: 50%;
  transform: translateY(-50%);
}

//////////////////////////////////////////////////

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

//////////////////////////////////////////////////

@mixin ltrb0 {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

//////////////////////////////////////////////////

@mixin invisible {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

@mixin visible {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

//////////////////////////////////////////////////

@mixin offscreen {
  visibility: hidden !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

@mixin offscreen-new {
  position: absolute !important;
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
}

//////////////////////////////////////////////////

@mixin truncate($overflow: ellipsis) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: $overflow; // values are: clip, ellipsis, or a string
}

//////////////////////////////////////////////////

@mixin word-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

@mixin break-urls {
  word-break: break-word;
  word-wrap: break-word;
  hyphens: none;
}

//////////////////////////////////////////////////
// From Compass

@mixin inline-block {
  display: inline-block;
  vertical-align: middle;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@function max($numbers...) {
  @return m#{a}x(#{$numbers});
}
